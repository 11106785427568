import { Easing } from '@tweenjs/tween.js'
import {
	BufferGeometry,
	Color,
	DoubleSide,
	Mesh,
	Object3D,
	PlaneGeometry,
	Quaternion,
	ShaderMaterial,
	Texture,
	TextureLoader,
	Vector3
} from 'three'

import Animator from '../animator/Animator'
import boxFragmentShader from './box.frag'
import boxVertexShader from './box.vert'
import font from './DaxOT-Bold.json'
import fontAtlas from './DaxOT-Bold.png'
import msdfFragmentShader from './msdf.frag'
import msdfVertexShader from './msdf.vert'
import { SDFGeometry } from './SDFGeometry'
import {ChaletState} from "../Chalet";

const PADDING = [0.5, 1.5]
const SCALE = 0.004

type Options = {
	id: ChaletState | string
	href: ChaletState | string
	parent: Object3D
	position: Vector3
	quaternion: Quaternion
	layers?: number[]
	size?: number
	border?: boolean
}

export default class Label extends Animator {
	public readonly mesh: Mesh<PlaneGeometry, ShaderMaterial>
	public readonly id: ChaletState | string
	public readonly href: ChaletState | string

	private readonly object: Object3D = new Object3D()
	private readonly texture: Texture
	private readonly boxMaterial: ShaderMaterial
	private readonly textMaterial: ShaderMaterial
	private readonly textGeometry: SDFGeometry
	private readonly textMesh: Mesh<BufferGeometry, ShaderMaterial>
	private readonly scale: number
	private isAnimating = false
	private isHover = false

	constructor({ id, href, parent, position, quaternion, layers = [], size = 1, border = true }: Options) {
		super()
		this.id = id
		this.href = href
		this.texture = new TextureLoader().load(fontAtlas)
		this.scale = SCALE * size

		this.textGeometry = new SDFGeometry({
			font,
			text: '',
			lineHeight: font.common.lineHeight * 0.85
		})

		this.textMaterial = new ShaderMaterial({
			extensions: {
				derivatives: true
			},
			vertexShader: msdfVertexShader,
			fragmentShader: msdfFragmentShader,
			side: DoubleSide,
			depthTest: false,
			depthWrite: false,
			transparent: true,
			uniforms: {
				color: { value: new Color(0xffffff) },
				map: { value: this.texture },
				opacity: { value: 1 }
			}
		})
		this.textMesh = new Mesh(this.textGeometry, this.textMaterial)
		this.textMesh.castShadow = false
		this.textMesh.receiveShadow = false
		this.textMesh.scale.y *= -1

		this.boxMaterial = new ShaderMaterial({
			extensions: {
				derivatives: true
			},
			vertexShader: boxVertexShader,
			fragmentShader: boxFragmentShader,
			depthTest: false,
			depthWrite: false,
			transparent: true,
			uniforms: {
				color: { value: new Color(0xffffff) },
				aspect: { value: 1 },
				lineWidth: { value: 0.02 },
				radius: { value: 0.06 },
				opacity: { value: 1 }
			}
		})

		this.mesh = new Mesh(new PlaneGeometry(1, 1, 1, 1), this.boxMaterial)
		this.mesh.castShadow = false
		this.mesh.receiveShadow = false
		this.mesh.userData.id = id
		this.mesh.userData.href = href
		this.mesh.visible = border
		layers.forEach((layer) => this.mesh.layers.enable(layer))

		this.object.position.copy(position)
		this.object.quaternion.copy(quaternion)

		this.object.scale.set(this.scale, this.scale, this.scale)

		this.object.add(this.textMesh)
		this.object.add(this.mesh)

		this.object.visible = false
		parent.add(this.object)
	}

	setText(text: string): void {
		this.textGeometry.update({ text: text })

		const { width, height, descender } = this.textGeometry.layout
		const padding = [PADDING[0] * height, PADDING[1] * height]
		const boxHeight = height + descender + padding[0]
		const boxWidth = width + padding[1]

		this.textMesh.position.x = width * -0.5
		this.textMesh.position.y = height * -0.4

		this.boxMaterial.uniforms.aspect.value = boxWidth / boxHeight
		this.mesh.scale.set(boxWidth, boxHeight, 1)
	}

	async show(): Promise<void> {
		this.isAnimating = true
		this.stopAnimations()

		this.object.visible = true
		this.textMaterial.uniforms.opacity.value = 0
		this.boxMaterial.uniforms.opacity.value = 0

		this.animate(this.textMaterial.uniforms.opacity, { value: 1 })
		await this.animate(this.boxMaterial.uniforms.opacity, { value: 1 })

		this.isAnimating = false
	}

	async hide(): Promise<void> {
		this.isAnimating = true

		this.stopAnimations()

		this.animate(this.textMaterial.uniforms.opacity, { value: 0 })
		await this.animate(this.boxMaterial.uniforms.opacity, { value: 0 })

		this.textMaterial.uniforms.opacity.value = 0
		this.boxMaterial.uniforms.opacity.value = 0

		this.object.visible = false
		this.isAnimating = false
	}

	onOver(): void {
		if (this.isAnimating) return
		this.isHover = true

		this.stopAnimations()
		const scale = this.scale * 1.1
		this.animate(this.object.scale, new Vector3(scale, scale, scale), { easing: Easing.Cubic.InOut })
		this.animate(this.boxMaterial.uniforms.lineWidth, { value: 0.04 }, { easing: Easing.Cubic.InOut })
	}

	onOut(): void {
		if (this.isAnimating) return
		if (!this.isHover) return
		this.isHover = false

		this.stopAnimations()
		this.animate(this.object.scale, new Vector3(this.scale, this.scale, this.scale), { easing: Easing.Cubic.InOut })
		this.animate(this.boxMaterial.uniforms.lineWidth, { value: 0.02 }, { easing: Easing.Cubic.InOut })
	}

	reset(): void {
		this.stopAnimations()
		this.object.scale.set(this.scale, this.scale, this.scale)
		this.boxMaterial.uniforms.lineWidth.value = 0.02
		this.isAnimating = false
		this.isHover = false
	}
}
