import { Vector2 } from 'three'

import ProductView from '@/ui/components/scene/productView/ProductView'

const FRICTION = 0.95
const MULTIPLIER = 5
const THRESHOLD = 0.01

export default class ProductControls {
	private readonly activePointers: PointerEvent[] = []
	private readonly pointer: Vector2 = new Vector2()
	private readonly lastPointer: Vector2 = new Vector2()
	private readonly velocity: Vector2 = new Vector2()
	private readonly rotation: Vector2 = new Vector2()

	private isVisible = false
	private isAutoRotating = false
	private isDecelerating = false
	private autoRotationStrength = 0
	private points = []

	constructor(
		private readonly domElement: HTMLElement,
		private readonly productView: ProductView
	) {
		this.onPointerStart = this.onPointerStart.bind(this)
		this.onPointerMove = this.onPointerMove.bind(this)
		this.onPointerEnd = this.onPointerEnd.bind(this)
	}

	start(): void {
		this.isVisible = true
		this.isAutoRotating = true
		this.autoRotationStrength = 0
		this.rotation.set(0, 0)
		this.domElement.addEventListener('pointerdown', this.onPointerStart)
	}

	stop(): void {
		this.isVisible = false
		this.domElement.removeEventListener('pointerdown', this.onPointerStart)
		this.removeListeners()
	}

	addListeners(): void {
		document.addEventListener('pointermove', this.onPointerMove)
		document.addEventListener('pointerup', this.onPointerEnd)
		document.addEventListener('pointercancel', this.onPointerEnd)
		document.addEventListener('pointerleave', this.onPointerEnd)
	}

	removeListeners(): void {
		document.removeEventListener('pointermove', this.onPointerMove)
		document.removeEventListener('pointerup', this.onPointerEnd)
		document.removeEventListener('pointercancel', this.onPointerEnd)
		document.removeEventListener('pointerleave', this.onPointerEnd)
	}

	onPointerStart(event: PointerEvent): void {
		const { x, y } = event
		const time = performance.now()

		this.isAutoRotating = false
		this.isDecelerating = false
		this.activePointers.push(event)
		this.pointer.set(x, y)
		this.lastPointer.set(x, y)
		this.points = []
		this.points.push({ x, y, time })

		this.addListeners()
	}

	onPointerMove(event: PointerEvent): void {
		for (let i = 0; i < this.activePointers.length; i++) {
			if (event.pointerId === this.activePointers[i].pointerId) {
				this.activePointers[i] = event
				break
			}
		}

		if (this.activePointers.length === 1) {
			const { x, y } = event
			this.pointer.set(event.x, event.y)

			const time = performance.now()
			while (this.points.length) {
				if (time - this.points[0].time <= 100) {
					break
				}
				this.points.shift()
			}

			this.points.push({ x, y, time })
		}
	}

	onPointerEnd(event: PointerEvent): void {
		const index = this.activePointers.findIndex(({ pointerId }) => pointerId === event.pointerId)
		if (index === -1) return

		this.activePointers.splice(index, 1)

		if (this.activePointers.length === 0) {
			const { x, y } = event
			const time = performance.now()
			this.points.push({ x, y, time })

			const firstPoint = this.points[0]
			const lastPoint = this.points[this.points.length - 1]

			const xOffset = lastPoint.x - firstPoint.x
			const yOffset = lastPoint.y - firstPoint.y
			const duration = lastPoint.time - firstPoint.time

			this.velocity.x = xOffset / duration || 0
			this.velocity.y = yOffset / duration || 0

			this.velocity.multiplyScalar(MULTIPLIER)
			this.isDecelerating = this.velocity.length() > THRESHOLD

			this.removeListeners()
		}
	}

	update(delta: number): void {
		if (!this.isVisible) return

		const factor = delta * 60

		if (this.isDecelerating) {
			this.velocity.x *= FRICTION
			this.velocity.y *= FRICTION
			this.rotation.x += this.velocity.x * factor
			this.rotation.y += this.velocity.y * factor

			this.isDecelerating = this.velocity.length() > THRESHOLD
			this.productView.onUpdate(this.rotation)
		} else if (this.activePointers.length === 1) {
			this.points.push({
				x: this.pointer.x - this.lastPointer.x,
				y: this.pointer.y - this.lastPointer.y,
				time: performance.now
			})

			const x = this.pointer.x - this.lastPointer.x
			const y = this.pointer.y - this.lastPointer.y
			this.lastPointer.copy(this.pointer)

			this.rotation.x += x * factor
			this.rotation.y += y * factor
			this.productView.onUpdate(this.rotation)
		} else if (this.isAutoRotating) {
			this.autoRotationStrength = Math.min(this.autoRotationStrength + factor * 0.02, 1)
			this.rotation.x += (1 / factor) * this.autoRotationStrength
			this.productView.onUpdate(this.rotation)
		}
	}
}
