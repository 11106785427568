#define GLSLIFY 1
uniform vec3 color;
uniform float aspect;
uniform float lineWidth;
uniform float radius;
uniform float opacity;

varying vec2 vUv;

float rect(vec2 uv, vec2 size, float radius) {
	return length(max(abs(uv), size) - size) - radius;
}

void main() {
	float offset = 0.005;

	vec2 uv = vUv;
	uv -= vec2(0.5);
	uv.x *= aspect;

	vec2 size = vec2(0.5);
	size.x *= aspect;
	size -= radius * 2.0;
	size -= offset;

	float d = rect(uv, size, radius);
	float b = abs(d / lineWidth) * 0.5;
	float afwidth = length(vec2(dFdx(b), dFdy(b))) * 0.70710678118654757;
	float alpha = 1.0 - smoothstep(0.5 - afwidth, 0.5 + afwidth, b);

	gl_FragColor = vec4(color, alpha * opacity);
	if (gl_FragColor.a < 0.0001) discard;
}
