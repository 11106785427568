import { Easing } from '@tweenjs/tween.js'
import { MathUtils, Matrix4, Object3D, Vector3 } from 'three'
import Animator from '../animator/Animator'

export class FireParticle extends Animator {
	public isRunning = false
	private readonly dummy: Object3D = new Object3D()
	private readonly index: number
	private readonly targetScale: Vector3 = new Vector3()
	private readonly targetPosition: Vector3 = new Vector3()
	private readonly targetRotation: Vector3 = new Vector3()
	private dissolve = 1

	constructor(index: number) {
		super()
		this.index = index
	}

	get matrix(): Matrix4 {
		this.dummy.updateMatrix()
		return this.dummy.matrix
	}

	start(): void {
		this.stopAnimations()
		this.isRunning = true
		const duration = 3000 + MathUtils.randFloat(-250, 250)
		const startRotation = MathUtils.randFloat(-Math.PI, Math.PI)
		const endRotation = startRotation + MathUtils.randFloat(-0.75, 0.75)
		const scale = 1 + MathUtils.randFloat(-0.25, 0.25)
		const x = MathUtils.randFloat(-0.5, 0.5)
		const y = duration / 1750

		this.dummy.scale.set(0, 0, 0)
		this.dummy.position.set(0, 0, 0)
		this.dummy.rotation.set(0, 0, startRotation)
		this.dissolve = 0.03

		this.targetScale.set(scale, scale, scale)
		this.targetPosition.set(x, y, this.index * 0.1)
		this.targetRotation.set(0, 0, endRotation)

		this.animate(this.dummy.scale, this.targetScale, {
			easing: Easing.Sinusoidal.Out,
			duration: duration * 0.5
		})

		this.animate(this.dummy.position, this.targetPosition, {
			easing: Easing.Quadratic.In,
			duration
		})

		this.animate(this.dummy.rotation, this.targetRotation, {
			duration
		})

		this.animate(
			{ dissolve: this.dissolve },
			{ dissolve: 1 },
			{
				easing: Easing.Cubic.InOut,
				duration,
				onUpdate: ({ dissolve }) => (this.dissolve = dissolve),
				onComplete: () => (this.isRunning = false)
			}
		)
	}
}
