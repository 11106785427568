#define GLSLIFY 1
uniform sampler2D baseTexture;
uniform float alpha;
uniform vec2 delta;
uniform vec2 resolution;

float random(vec3 scale, float seed) {
	return fract(sin(dot(gl_FragCoord.xyz + seed, scale)) * 43758.5453 + seed);
}

void main() {
	vec2 uv = gl_FragCoord.xy / resolution.xy;

	vec4 color = vec4(0.0);

	float total = 0.0;

	float offset = random(vec3(12.9898, 78.233, 151.7182), 0.0);

	for (float t = -10.0; t <= 10.0; t++) {
		float percent = (t + offset - 0.5) / 10.0;
		float weight = 1.0 - abs(percent);
		vec4 sampleColor = texture2D(baseTexture, uv + delta * percent);

		color += sampleColor * weight;
		total += weight;
	}

	gl_FragColor = color / total;
}
