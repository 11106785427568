import { Easing } from '@tweenjs/tween.js'
import { Mesh } from 'three'

import { IntroElementType } from '@/providers/Chalet'
import Chalet from '@/ui/components/scene/Chalet'

import Animator from '../animator/Animator'

export default class IntroElement extends Animator {
	private readonly transform = { scale: 1, y: 0 }
	private isVisible = false

	constructor(
		private readonly mesh: Mesh,
		public readonly name: IntroElementType,
		private readonly chalet: Chalet
	) {
		super()
		this.mesh.visible = false
	}

	hide(): void {
		this.isVisible = false
		this.mesh.visible = false
	}

	show(): void {
		this.isVisible = true
		this.mesh.visible = true

		this.transform.scale = 0.75
		this.transform.y = -0.25

		this.animate(this.transform, { scale: 1, y: 0 }, { duration: 750, easing: Easing.Back.Out })
	}

	update(time: number): void {
		if (!this.isVisible) return
		this.mesh.scale.set(this.transform.scale, this.transform.scale, this.transform.scale)
		this.mesh.rotation.y = Math.sin(time * 0.001) * 0.075 - 0.0375
		this.mesh.position.y = this.transform.y + Math.sin(time * 0.001) * 0.05
		this.chalet.composer.requestRender()
	}
}
