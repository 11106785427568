import { Easing } from '@tweenjs/tween.js'
import { MathUtils, Matrix4, Object3D, Vector3 } from 'three'
import Animator from '../animator/Animator'

export class SmokeParticle extends Animator {
	public isRunning = false
	public opacity = 0
	public elapsed = 0
	private readonly dummy: Object3D = new Object3D()
	private readonly index: number

	constructor(index: number) {
		super()
		this.index = index
	}

	get matrix(): Matrix4 {
		this.dummy.updateMatrix()
		return this.dummy.matrix
	}

	start(): void {
		this.stopAnimations()
		this.isRunning = true
		const duration = 6000 + MathUtils.randFloat(-1000, 1000)
		const startRotation = MathUtils.randFloat(-Math.PI, Math.PI)
		const endRotation = startRotation + MathUtils.randFloat(-Math.PI, Math.PI)
		const scale = 0.85 + MathUtils.randFloat(-0.35, 0.35)
		const x = MathUtils.randFloat(-0.25, 0.25)
		const y = duration / 4000
		const opacity = MathUtils.randFloat(0.15, 1)

		this.dummy.scale.set(0, 0, 0)
		this.dummy.position.set(0, 0, 0)
		this.dummy.rotation.set(0, 0, startRotation)

		const startPosition = this.dummy.position.clone()
		const targetPosition = new Vector3(x, y, this.index * 0.1)

		this.animate(
			{ elapsed: 0 },
			{ elapsed: 1 },
			{
				duration,
				onUpdate: ({ elapsed }) => {
					this.dummy.position.lerpVectors(startPosition, targetPosition, elapsed)
					this.dummy.rotation.z = MathUtils.lerp(startRotation, endRotation, elapsed)
					const s = Easing.Sinusoidal.Out(elapsed) * scale
					// const s = elapsed * scale
					this.dummy.scale.set(s, s, s)
					const o = elapsed <= 0.25 ? elapsed : elapsed >= 0.75 ? 1 - elapsed : 0.25
					this.opacity = o * 4 * opacity
					this.elapsed = elapsed
				},
				onComplete: () => (this.isRunning = false)
			}
		)
	}
}
