#define GLSLIFY 1
uniform float opacity;
uniform vec3 color;
uniform sampler2D map;
varying vec2 vUv;

float median(float r, float g, float b) {
	return max(min(r, g), min(max(r, g), b));
}

void main() {
	vec3 s = texture2D(map, vUv).rgb;
	float sigDist = median(s.r, s.g, s.b) - 0.5;
	float alpha = clamp(sigDist / fwidth(sigDist) + 0.5, 0.0, 1.0);
	gl_FragColor = vec4(color.xyz, alpha * opacity);
	if (gl_FragColor.a < 0.0001) discard;
}
