precision highp float;
#define GLSLIFY 1

uniform sampler2D map;
uniform vec3 color;

varying vec2 vUv;
varying vec3 vColor;

void main() {
	float alpha = texture2D(map, vUv).r;
	gl_FragColor = vec4(vColor, alpha);
}
