import { Color, Mesh, PlaneGeometry, ShaderMaterial } from 'three'

import Animator from '../animator/Animator'
import fragmentShader from './fade.frag'
import vertexShader from './fade.vert'
import Chalet from '../Chalet'
import delay from '../helper/delay'

export default class Fade extends Animator {
	private readonly object: Mesh<PlaneGeometry, ShaderMaterial>

	constructor(
		color: string,
		private readonly chalet: Chalet
	) {
		super()

		const material = new ShaderMaterial({
			transparent: true,
			depthWrite: false,
			depthTest: false,
			fragmentShader,
			vertexShader,
			uniforms: {
				color: { value: new Color(color) },
				alpha: { value: 0 }
			}
		})

		const geometry = new PlaneGeometry(2, 2)

		this.object = new Mesh(geometry, material)
		this.object.renderOrder = 1000
		this.object.frustumCulled = false
		this.object.visible = false

		this.chalet.scene.add(this.object)
	}

	async show(wait = 0): Promise<void> {
		if (this.object.visible) return
		this.stopAnimations()
		await delay(wait)
		this.object.visible = true
		await this.animate(
			this.object.material.uniforms.alpha,
			{ value: 1 },
			{ duration: 500, onUpdate: () => this.chalet.composer.requestRender() }
		)
	}

	async hide(): Promise<void> {
		if (!this.object.visible) return

		await this.animate(
			this.object.material.uniforms.alpha,
			{ value: 0 },
			{ duration: 500, onUpdate: () => this.chalet.composer.requestRender() }
		)
		this.object.visible = false
	}
}
