import { Easing, Tween } from '@tweenjs/tween.js'

export default class Animator {
	private animations: Tween<any>[] = []

	stopAnimations() {
		this.animations.forEach((tween) => tween.stop())
		this.animations = []
	}

	async animate(
		from: any,
		to: any,
		{
			duration = 250,
			easing = Easing.Linear.None,
			onStart = () => undefined,
			onUpdate = (values: any) => undefined,
			onComplete = () => undefined
		} = {}
	): Promise<void> {
		return new Promise<void>((resolve) => {
			this.animations.push(
				new Tween(from)
					.to(to, duration)
					.easing(easing)
					.onStart(onStart)
					.onUpdate(onUpdate)
					.onComplete(() => {
						onComplete()
						resolve()
					})
					.start()
			)
		})
	}
}
