precision highp float;
#define GLSLIFY 1

uniform sampler2D map;
uniform vec3 color;

varying vec2 vUv;
varying float vOpacity;

void main() {
	gl_FragColor = texture2D(map, vUv) * vec4(color, vOpacity);
}
