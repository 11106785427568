{
	"pages": ["DaxOT-Bold.png"],
	"chars": [
		{
			"id": 47,
			"index": 18,
			"char": "/",
			"width": 23,
			"height": 47,
			"xoffset": -2,
			"yoffset": 0,
			"xadvance": 20,
			"chnl": 15,
			"x": 0,
			"y": 0,
			"page": 0
		},
		{
			"id": 74,
			"index": 45,
			"char": "J",
			"width": 15,
			"height": 42,
			"xoffset": -4,
			"yoffset": 5,
			"xadvance": 12,
			"chnl": 15,
			"x": 0,
			"y": 48,
			"page": 0
		},
		{
			"id": 81,
			"index": 52,
			"char": "Q",
			"width": 27,
			"height": 42,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 26,
			"chnl": 15,
			"x": 0,
			"y": 91,
			"page": 0
		},
		{
			"id": 192,
			"index": 129,
			"char": "À",
			"width": 29,
			"height": 42,
			"xoffset": -2,
			"yoffset": -4,
			"xadvance": 26,
			"chnl": 15,
			"x": 16,
			"y": 48,
			"page": 0
		},
		{
			"id": 194,
			"index": 131,
			"char": "Â",
			"width": 29,
			"height": 42,
			"xoffset": -2,
			"yoffset": -4,
			"xadvance": 26,
			"chnl": 15,
			"x": 24,
			"y": 0,
			"page": 0
		},
		{
			"id": 200,
			"index": 137,
			"char": "È",
			"width": 19,
			"height": 42,
			"xoffset": 1,
			"yoffset": -4,
			"xadvance": 19,
			"chnl": 15,
			"x": 0,
			"y": 134,
			"page": 0
		},
		{
			"id": 201,
			"index": 138,
			"char": "É",
			"width": 19,
			"height": 42,
			"xoffset": 1,
			"yoffset": -4,
			"xadvance": 19,
			"chnl": 15,
			"x": 0,
			"y": 177,
			"page": 0
		},
		{
			"id": 202,
			"index": 139,
			"char": "Ê",
			"width": 19,
			"height": 42,
			"xoffset": 1,
			"yoffset": -4,
			"xadvance": 19,
			"chnl": 15,
			"x": 20,
			"y": 134,
			"page": 0
		},
		{
			"id": 206,
			"index": 143,
			"char": "Î",
			"width": 17,
			"height": 42,
			"xoffset": -2,
			"yoffset": -4,
			"xadvance": 12,
			"chnl": 15,
			"x": 28,
			"y": 91,
			"page": 0
		},
		{
			"id": 212,
			"index": 149,
			"char": "Ô",
			"width": 27,
			"height": 42,
			"xoffset": 0,
			"yoffset": -4,
			"xadvance": 26,
			"chnl": 15,
			"x": 20,
			"y": 177,
			"page": 0
		},
		{
			"id": 217,
			"index": 154,
			"char": "Ù",
			"width": 24,
			"height": 42,
			"xoffset": 1,
			"yoffset": -4,
			"xadvance": 26,
			"chnl": 15,
			"x": 40,
			"y": 134,
			"page": 0
		},
		{
			"id": 219,
			"index": 156,
			"char": "Û",
			"width": 24,
			"height": 42,
			"xoffset": 1,
			"yoffset": -4,
			"xadvance": 26,
			"chnl": 15,
			"x": 48,
			"y": 177,
			"page": 0
		},
		{
			"id": 220,
			"index": 157,
			"char": "Ü",
			"width": 24,
			"height": 41,
			"xoffset": 1,
			"yoffset": -2,
			"xadvance": 26,
			"chnl": 15,
			"x": 46,
			"y": 43,
			"page": 0
		},
		{
			"id": 196,
			"index": 133,
			"char": "Ä",
			"width": 29,
			"height": 40,
			"xoffset": -2,
			"yoffset": -2,
			"xadvance": 26,
			"chnl": 15,
			"x": 54,
			"y": 0,
			"page": 0
		},
		{
			"id": 87,
			"index": 58,
			"char": "W",
			"width": 38,
			"height": 33,
			"xoffset": -1,
			"yoffset": 5,
			"xadvance": 35,
			"chnl": 15,
			"x": 0,
			"y": 220,
			"page": 0
		},
		{
			"id": 66,
			"index": 37,
			"char": "B",
			"width": 25,
			"height": 34,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 25,
			"chnl": 15,
			"x": 39,
			"y": 220,
			"page": 0
		},
		{
			"id": 67,
			"index": 38,
			"char": "C",
			"width": 22,
			"height": 34,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 21,
			"chnl": 15,
			"x": 65,
			"y": 220,
			"page": 0
		},
		{
			"id": 68,
			"index": 39,
			"char": "D",
			"width": 25,
			"height": 34,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 26,
			"chnl": 15,
			"x": 46,
			"y": 85,
			"page": 0
		},
		{
			"id": 71,
			"index": 42,
			"char": "G",
			"width": 25,
			"height": 34,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 26,
			"chnl": 15,
			"x": 71,
			"y": 41,
			"page": 0
		},
		{
			"id": 77,
			"index": 48,
			"char": "M",
			"width": 34,
			"height": 33,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 34,
			"chnl": 15,
			"x": 84,
			"y": 0,
			"page": 0
		},
		{
			"id": 79,
			"index": 50,
			"char": "O",
			"width": 27,
			"height": 34,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 26,
			"chnl": 15,
			"x": 65,
			"y": 120,
			"page": 0
		},
		{
			"id": 83,
			"index": 54,
			"char": "S",
			"width": 21,
			"height": 34,
			"xoffset": -1,
			"yoffset": 5,
			"xadvance": 20,
			"chnl": 15,
			"x": 72,
			"y": 76,
			"page": 0
		},
		{
			"id": 51,
			"index": 22,
			"char": "3",
			"width": 24,
			"height": 34,
			"xoffset": -1,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 73,
			"y": 155,
			"page": 0
		},
		{
			"id": 54,
			"index": 25,
			"char": "6",
			"width": 24,
			"height": 34,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 93,
			"y": 111,
			"page": 0
		},
		{
			"id": 56,
			"index": 27,
			"char": "8",
			"width": 24,
			"height": 34,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 94,
			"y": 76,
			"page": 0
		},
		{
			"id": 57,
			"index": 28,
			"char": "9",
			"width": 24,
			"height": 34,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 97,
			"y": 34,
			"page": 0
		},
		{
			"id": 48,
			"index": 19,
			"char": "0",
			"width": 24,
			"height": 34,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 88,
			"y": 190,
			"page": 0
		},
		{
			"id": 65,
			"index": 36,
			"char": "A",
			"width": 29,
			"height": 33,
			"xoffset": -2,
			"yoffset": 5,
			"xadvance": 26,
			"chnl": 15,
			"x": 119,
			"y": 0,
			"page": 0
		},
		{
			"id": 69,
			"index": 40,
			"char": "E",
			"width": 19,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 19,
			"chnl": 15,
			"x": 98,
			"y": 146,
			"page": 0
		},
		{
			"id": 70,
			"index": 41,
			"char": "F",
			"width": 19,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 19,
			"chnl": 15,
			"x": 113,
			"y": 180,
			"page": 0
		},
		{
			"id": 72,
			"index": 43,
			"char": "H",
			"width": 25,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 26,
			"chnl": 15,
			"x": 113,
			"y": 214,
			"page": 0
		},
		{
			"id": 73,
			"index": 44,
			"char": "I",
			"width": 11,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 12,
			"chnl": 15,
			"x": 118,
			"y": 111,
			"page": 0
		},
		{
			"id": 75,
			"index": 46,
			"char": "K",
			"width": 26,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 25,
			"chnl": 15,
			"x": 118,
			"y": 145,
			"page": 0
		},
		{
			"id": 76,
			"index": 47,
			"char": "L",
			"width": 18,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 18,
			"chnl": 15,
			"x": 133,
			"y": 179,
			"page": 0
		},
		{
			"id": 78,
			"index": 49,
			"char": "N",
			"width": 26,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 27,
			"chnl": 15,
			"x": 119,
			"y": 69,
			"page": 0
		},
		{
			"id": 80,
			"index": 51,
			"char": "P",
			"width": 24,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 122,
			"y": 34,
			"page": 0
		},
		{
			"id": 82,
			"index": 53,
			"char": "R",
			"width": 25,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 25,
			"chnl": 15,
			"x": 130,
			"y": 103,
			"page": 0
		},
		{
			"id": 84,
			"index": 55,
			"char": "T",
			"width": 21,
			"height": 33,
			"xoffset": -1,
			"yoffset": 5,
			"xadvance": 20,
			"chnl": 15,
			"x": 146,
			"y": 68,
			"page": 0
		},
		{
			"id": 85,
			"index": 56,
			"char": "U",
			"width": 24,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 26,
			"chnl": 15,
			"x": 147,
			"y": 34,
			"page": 0
		},
		{
			"id": 86,
			"index": 57,
			"char": "V",
			"width": 27,
			"height": 33,
			"xoffset": -2,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 149,
			"y": 0,
			"page": 0
		},
		{
			"id": 88,
			"index": 59,
			"char": "X",
			"width": 27,
			"height": 33,
			"xoffset": -2,
			"yoffset": 5,
			"xadvance": 23,
			"chnl": 15,
			"x": 145,
			"y": 137,
			"page": 0
		},
		{
			"id": 89,
			"index": 60,
			"char": "Y",
			"width": 26,
			"height": 33,
			"xoffset": -2,
			"yoffset": 5,
			"xadvance": 22,
			"chnl": 15,
			"x": 156,
			"y": 102,
			"page": 0
		},
		{
			"id": 90,
			"index": 61,
			"char": "Z",
			"width": 22,
			"height": 33,
			"xoffset": -1,
			"yoffset": 5,
			"xadvance": 20,
			"chnl": 15,
			"x": 168,
			"y": 68,
			"page": 0
		},
		{
			"id": 49,
			"index": 20,
			"char": "1",
			"width": 18,
			"height": 33,
			"xoffset": 1,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 172,
			"y": 34,
			"page": 0
		},
		{
			"id": 50,
			"index": 21,
			"char": "2",
			"width": 23,
			"height": 33,
			"xoffset": -1,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 177,
			"y": 0,
			"page": 0
		},
		{
			"id": 52,
			"index": 23,
			"char": "4",
			"width": 24,
			"height": 33,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 139,
			"y": 213,
			"page": 0
		},
		{
			"id": 53,
			"index": 24,
			"char": "5",
			"width": 24,
			"height": 33,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 152,
			"y": 171,
			"page": 0
		},
		{
			"id": 55,
			"index": 26,
			"char": "7",
			"width": 24,
			"height": 33,
			"xoffset": 0,
			"yoffset": 5,
			"xadvance": 24,
			"chnl": 15,
			"x": 173,
			"y": 136,
			"page": 0
		},
		{
			"id": 32,
			"index": 3,
			"char": " ",
			"width": 0,
			"height": 0,
			"xoffset": -2,
			"yoffset": 34,
			"xadvance": 11,
			"chnl": 15,
			"x": 71,
			"y": 76,
			"page": 0
		}
	],
	"info": {
		"face": "DaxOT-Bold",
		"size": 42,
		"bold": 0,
		"italic": 0,
		"charset": [
			"A",
			"B",
			"C",
			"D",
			"E",
			"F",
			"G",
			"H",
			"I",
			"J",
			"K",
			"L",
			"M",
			"N",
			"O",
			"P",
			"Q",
			"R",
			"S",
			"T",
			"U",
			"V",
			"W",
			"X",
			"Y",
			"Z",
			"À",
			"Â",
			"Ä",
			"È",
			"É",
			"Ê",
			"Î",
			"Ô",
			"Ù",
			"Û",
			"Ü",
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"0",
			"/",
			" "
		],
		"unicode": 1,
		"stretchH": 100,
		"smooth": 1,
		"aa": 1,
		"padding": [2, 2, 2, 2],
		"spacing": [0, 0]
	},
	"common": {
		"lineHeight": 65,
		"base": 34,
		"scaleW": 256,
		"scaleH": 256,
		"pages": 1,
		"packed": 0,
		"alphaChnl": 0,
		"redChnl": 0,
		"greenChnl": 0,
		"blueChnl": 0
	},
	"distanceField": {
		"fieldType": "msdf",
		"distanceRange": 4
	},
	"kernings": [
		{
			"first": 65,
			"second": 67,
			"amount": -1
		},
		{
			"first": 65,
			"second": 71,
			"amount": -1
		},
		{
			"first": 65,
			"second": 74,
			"amount": 1
		},
		{
			"first": 65,
			"second": 79,
			"amount": -1
		},
		{
			"first": 65,
			"second": 81,
			"amount": -1
		},
		{
			"first": 65,
			"second": 83,
			"amount": 1
		},
		{
			"first": 65,
			"second": 84,
			"amount": -2
		},
		{
			"first": 65,
			"second": 85,
			"amount": 0
		},
		{
			"first": 65,
			"second": 86,
			"amount": -2
		},
		{
			"first": 65,
			"second": 87,
			"amount": -2
		},
		{
			"first": 65,
			"second": 89,
			"amount": -2
		},
		{
			"first": 65,
			"second": 212,
			"amount": -1
		},
		{
			"first": 65,
			"second": 217,
			"amount": 0
		},
		{
			"first": 65,
			"second": 219,
			"amount": 0
		},
		{
			"first": 65,
			"second": 220,
			"amount": 0
		},
		{
			"first": 66,
			"second": 65,
			"amount": 0
		},
		{
			"first": 66,
			"second": 67,
			"amount": 0
		},
		{
			"first": 66,
			"second": 71,
			"amount": 0
		},
		{
			"first": 66,
			"second": 79,
			"amount": 0
		},
		{
			"first": 66,
			"second": 81,
			"amount": 0
		},
		{
			"first": 66,
			"second": 84,
			"amount": -1
		},
		{
			"first": 66,
			"second": 86,
			"amount": -1
		},
		{
			"first": 66,
			"second": 87,
			"amount": -1
		},
		{
			"first": 66,
			"second": 89,
			"amount": -1
		},
		{
			"first": 66,
			"second": 192,
			"amount": 0
		},
		{
			"first": 66,
			"second": 194,
			"amount": 0
		},
		{
			"first": 66,
			"second": 196,
			"amount": 0
		},
		{
			"first": 66,
			"second": 212,
			"amount": 0
		},
		{
			"first": 67,
			"second": 67,
			"amount": -1
		},
		{
			"first": 67,
			"second": 71,
			"amount": -1
		},
		{
			"first": 67,
			"second": 79,
			"amount": -1
		},
		{
			"first": 67,
			"second": 81,
			"amount": -1
		},
		{
			"first": 67,
			"second": 212,
			"amount": -1
		},
		{
			"first": 68,
			"second": 65,
			"amount": -1
		},
		{
			"first": 68,
			"second": 77,
			"amount": 0
		},
		{
			"first": 68,
			"second": 84,
			"amount": -1
		},
		{
			"first": 68,
			"second": 86,
			"amount": -1
		},
		{
			"first": 68,
			"second": 87,
			"amount": -1
		},
		{
			"first": 68,
			"second": 89,
			"amount": -1
		},
		{
			"first": 68,
			"second": 90,
			"amount": -1
		},
		{
			"first": 68,
			"second": 192,
			"amount": -1
		},
		{
			"first": 68,
			"second": 194,
			"amount": -1
		},
		{
			"first": 68,
			"second": 196,
			"amount": -1
		},
		{
			"first": 69,
			"second": 65,
			"amount": -1
		},
		{
			"first": 69,
			"second": 67,
			"amount": -1
		},
		{
			"first": 69,
			"second": 71,
			"amount": -1
		},
		{
			"first": 69,
			"second": 74,
			"amount": 0
		},
		{
			"first": 69,
			"second": 79,
			"amount": -1
		},
		{
			"first": 69,
			"second": 81,
			"amount": -1
		},
		{
			"first": 69,
			"second": 84,
			"amount": 0
		},
		{
			"first": 69,
			"second": 192,
			"amount": -1
		},
		{
			"first": 69,
			"second": 194,
			"amount": -1
		},
		{
			"first": 69,
			"second": 196,
			"amount": -1
		},
		{
			"first": 69,
			"second": 212,
			"amount": -1
		},
		{
			"first": 70,
			"second": 65,
			"amount": -2
		},
		{
			"first": 70,
			"second": 67,
			"amount": 0
		},
		{
			"first": 70,
			"second": 71,
			"amount": 0
		},
		{
			"first": 70,
			"second": 77,
			"amount": -1
		},
		{
			"first": 70,
			"second": 79,
			"amount": 0
		},
		{
			"first": 70,
			"second": 90,
			"amount": -1
		},
		{
			"first": 70,
			"second": 192,
			"amount": -2
		},
		{
			"first": 70,
			"second": 194,
			"amount": -2
		},
		{
			"first": 70,
			"second": 196,
			"amount": -2
		},
		{
			"first": 70,
			"second": 212,
			"amount": 0
		},
		{
			"first": 71,
			"second": 84,
			"amount": -1
		},
		{
			"first": 71,
			"second": 86,
			"amount": -1
		},
		{
			"first": 71,
			"second": 87,
			"amount": -1
		},
		{
			"first": 71,
			"second": 89,
			"amount": -1
		},
		{
			"first": 75,
			"second": 67,
			"amount": -1
		},
		{
			"first": 75,
			"second": 71,
			"amount": -1
		},
		{
			"first": 75,
			"second": 74,
			"amount": 1
		},
		{
			"first": 75,
			"second": 79,
			"amount": -1
		},
		{
			"first": 75,
			"second": 81,
			"amount": -1
		},
		{
			"first": 75,
			"second": 85,
			"amount": 0
		},
		{
			"first": 75,
			"second": 212,
			"amount": -1
		},
		{
			"first": 75,
			"second": 217,
			"amount": 0
		},
		{
			"first": 75,
			"second": 219,
			"amount": 0
		},
		{
			"first": 75,
			"second": 220,
			"amount": 0
		},
		{
			"first": 76,
			"second": 67,
			"amount": -1
		},
		{
			"first": 76,
			"second": 71,
			"amount": -1
		},
		{
			"first": 76,
			"second": 79,
			"amount": -1
		},
		{
			"first": 76,
			"second": 81,
			"amount": -1
		},
		{
			"first": 76,
			"second": 84,
			"amount": -3
		},
		{
			"first": 76,
			"second": 85,
			"amount": -1
		},
		{
			"first": 76,
			"second": 86,
			"amount": -2
		},
		{
			"first": 76,
			"second": 87,
			"amount": -2
		},
		{
			"first": 76,
			"second": 89,
			"amount": -3
		},
		{
			"first": 76,
			"second": 212,
			"amount": -1
		},
		{
			"first": 76,
			"second": 217,
			"amount": -1
		},
		{
			"first": 76,
			"second": 219,
			"amount": -1
		},
		{
			"first": 76,
			"second": 220,
			"amount": -1
		},
		{
			"first": 77,
			"second": 67,
			"amount": 0
		},
		{
			"first": 77,
			"second": 71,
			"amount": 0
		},
		{
			"first": 77,
			"second": 79,
			"amount": 0
		},
		{
			"first": 77,
			"second": 81,
			"amount": 0
		},
		{
			"first": 77,
			"second": 84,
			"amount": -1
		},
		{
			"first": 77,
			"second": 85,
			"amount": 0
		},
		{
			"first": 77,
			"second": 86,
			"amount": -1
		},
		{
			"first": 77,
			"second": 87,
			"amount": -1
		},
		{
			"first": 77,
			"second": 89,
			"amount": -1
		},
		{
			"first": 77,
			"second": 212,
			"amount": 0
		},
		{
			"first": 77,
			"second": 217,
			"amount": 0
		},
		{
			"first": 77,
			"second": 219,
			"amount": 0
		},
		{
			"first": 77,
			"second": 220,
			"amount": 0
		},
		{
			"first": 79,
			"second": 65,
			"amount": -1
		},
		{
			"first": 79,
			"second": 77,
			"amount": 0
		},
		{
			"first": 79,
			"second": 84,
			"amount": -1
		},
		{
			"first": 79,
			"second": 86,
			"amount": -1
		},
		{
			"first": 79,
			"second": 87,
			"amount": -1
		},
		{
			"first": 79,
			"second": 88,
			"amount": -1
		},
		{
			"first": 79,
			"second": 89,
			"amount": -1
		},
		{
			"first": 79,
			"second": 90,
			"amount": -1
		},
		{
			"first": 79,
			"second": 192,
			"amount": -1
		},
		{
			"first": 79,
			"second": 194,
			"amount": -1
		},
		{
			"first": 79,
			"second": 196,
			"amount": -1
		},
		{
			"first": 80,
			"second": 65,
			"amount": -3
		},
		{
			"first": 80,
			"second": 77,
			"amount": -1
		},
		{
			"first": 80,
			"second": 84,
			"amount": 0
		},
		{
			"first": 80,
			"second": 90,
			"amount": -1
		},
		{
			"first": 80,
			"second": 192,
			"amount": -3
		},
		{
			"first": 80,
			"second": 194,
			"amount": -3
		},
		{
			"first": 80,
			"second": 196,
			"amount": -3
		},
		{
			"first": 82,
			"second": 67,
			"amount": 0
		},
		{
			"first": 82,
			"second": 71,
			"amount": 0
		},
		{
			"first": 82,
			"second": 74,
			"amount": 1
		},
		{
			"first": 82,
			"second": 79,
			"amount": 0
		},
		{
			"first": 82,
			"second": 81,
			"amount": 0
		},
		{
			"first": 82,
			"second": 84,
			"amount": -1
		},
		{
			"first": 82,
			"second": 89,
			"amount": 0
		},
		{
			"first": 82,
			"second": 212,
			"amount": 0
		},
		{
			"first": 83,
			"second": 65,
			"amount": 0
		},
		{
			"first": 83,
			"second": 83,
			"amount": 0
		},
		{
			"first": 83,
			"second": 84,
			"amount": -1
		},
		{
			"first": 83,
			"second": 86,
			"amount": 0
		},
		{
			"first": 83,
			"second": 87,
			"amount": -1
		},
		{
			"first": 83,
			"second": 89,
			"amount": -1
		},
		{
			"first": 83,
			"second": 192,
			"amount": 0
		},
		{
			"first": 83,
			"second": 194,
			"amount": 0
		},
		{
			"first": 83,
			"second": 196,
			"amount": 0
		},
		{
			"first": 84,
			"second": 65,
			"amount": -2
		},
		{
			"first": 84,
			"second": 67,
			"amount": -1
		},
		{
			"first": 84,
			"second": 71,
			"amount": -1
		},
		{
			"first": 84,
			"second": 77,
			"amount": -1
		},
		{
			"first": 84,
			"second": 79,
			"amount": -1
		},
		{
			"first": 84,
			"second": 81,
			"amount": -1
		},
		{
			"first": 84,
			"second": 83,
			"amount": 0
		},
		{
			"first": 84,
			"second": 84,
			"amount": -1
		},
		{
			"first": 84,
			"second": 90,
			"amount": 0
		},
		{
			"first": 84,
			"second": 192,
			"amount": -2
		},
		{
			"first": 84,
			"second": 194,
			"amount": -2
		},
		{
			"first": 84,
			"second": 196,
			"amount": -2
		},
		{
			"first": 84,
			"second": 212,
			"amount": -1
		},
		{
			"first": 85,
			"second": 65,
			"amount": 0
		},
		{
			"first": 85,
			"second": 88,
			"amount": 0
		},
		{
			"first": 85,
			"second": 192,
			"amount": 0
		},
		{
			"first": 85,
			"second": 194,
			"amount": 0
		},
		{
			"first": 85,
			"second": 196,
			"amount": 0
		},
		{
			"first": 86,
			"second": 65,
			"amount": -2
		},
		{
			"first": 86,
			"second": 67,
			"amount": -1
		},
		{
			"first": 86,
			"second": 71,
			"amount": -1
		},
		{
			"first": 86,
			"second": 77,
			"amount": 0
		},
		{
			"first": 86,
			"second": 79,
			"amount": -1
		},
		{
			"first": 86,
			"second": 192,
			"amount": -2
		},
		{
			"first": 86,
			"second": 194,
			"amount": -2
		},
		{
			"first": 86,
			"second": 196,
			"amount": -2
		},
		{
			"first": 86,
			"second": 212,
			"amount": -1
		},
		{
			"first": 87,
			"second": 65,
			"amount": -2
		},
		{
			"first": 87,
			"second": 67,
			"amount": -1
		},
		{
			"first": 87,
			"second": 71,
			"amount": -1
		},
		{
			"first": 87,
			"second": 77,
			"amount": -1
		},
		{
			"first": 87,
			"second": 79,
			"amount": -1
		},
		{
			"first": 87,
			"second": 192,
			"amount": -2
		},
		{
			"first": 87,
			"second": 194,
			"amount": -2
		},
		{
			"first": 87,
			"second": 196,
			"amount": -2
		},
		{
			"first": 87,
			"second": 212,
			"amount": -1
		},
		{
			"first": 88,
			"second": 67,
			"amount": -1
		},
		{
			"first": 88,
			"second": 71,
			"amount": -1
		},
		{
			"first": 88,
			"second": 79,
			"amount": -1
		},
		{
			"first": 88,
			"second": 81,
			"amount": -1
		},
		{
			"first": 88,
			"second": 84,
			"amount": 0
		},
		{
			"first": 88,
			"second": 85,
			"amount": 0
		},
		{
			"first": 88,
			"second": 212,
			"amount": -1
		},
		{
			"first": 88,
			"second": 217,
			"amount": 0
		},
		{
			"first": 88,
			"second": 219,
			"amount": 0
		},
		{
			"first": 88,
			"second": 220,
			"amount": 0
		},
		{
			"first": 89,
			"second": 65,
			"amount": -2
		},
		{
			"first": 89,
			"second": 67,
			"amount": -1
		},
		{
			"first": 89,
			"second": 71,
			"amount": -1
		},
		{
			"first": 89,
			"second": 77,
			"amount": -1
		},
		{
			"first": 89,
			"second": 79,
			"amount": -1
		},
		{
			"first": 89,
			"second": 81,
			"amount": -1
		},
		{
			"first": 89,
			"second": 192,
			"amount": -2
		},
		{
			"first": 89,
			"second": 194,
			"amount": -2
		},
		{
			"first": 89,
			"second": 196,
			"amount": -2
		},
		{
			"first": 89,
			"second": 212,
			"amount": -1
		},
		{
			"first": 90,
			"second": 65,
			"amount": 0
		},
		{
			"first": 90,
			"second": 67,
			"amount": -1
		},
		{
			"first": 90,
			"second": 71,
			"amount": -1
		},
		{
			"first": 90,
			"second": 79,
			"amount": -1
		},
		{
			"first": 90,
			"second": 81,
			"amount": -1
		},
		{
			"first": 90,
			"second": 84,
			"amount": -1
		},
		{
			"first": 90,
			"second": 86,
			"amount": 0
		},
		{
			"first": 90,
			"second": 87,
			"amount": 0
		},
		{
			"first": 90,
			"second": 192,
			"amount": 0
		},
		{
			"first": 90,
			"second": 194,
			"amount": 0
		},
		{
			"first": 90,
			"second": 196,
			"amount": 0
		},
		{
			"first": 90,
			"second": 212,
			"amount": -1
		},
		{
			"first": 192,
			"second": 67,
			"amount": -1
		},
		{
			"first": 192,
			"second": 71,
			"amount": -1
		},
		{
			"first": 192,
			"second": 74,
			"amount": 1
		},
		{
			"first": 192,
			"second": 79,
			"amount": -1
		},
		{
			"first": 192,
			"second": 81,
			"amount": -1
		},
		{
			"first": 192,
			"second": 83,
			"amount": 1
		},
		{
			"first": 192,
			"second": 84,
			"amount": -2
		},
		{
			"first": 192,
			"second": 85,
			"amount": 0
		},
		{
			"first": 192,
			"second": 86,
			"amount": -2
		},
		{
			"first": 192,
			"second": 87,
			"amount": -2
		},
		{
			"first": 192,
			"second": 89,
			"amount": -2
		},
		{
			"first": 194,
			"second": 67,
			"amount": -1
		},
		{
			"first": 194,
			"second": 71,
			"amount": -1
		},
		{
			"first": 194,
			"second": 74,
			"amount": 1
		},
		{
			"first": 194,
			"second": 79,
			"amount": -1
		},
		{
			"first": 194,
			"second": 81,
			"amount": -1
		},
		{
			"first": 194,
			"second": 83,
			"amount": 1
		},
		{
			"first": 194,
			"second": 84,
			"amount": -2
		},
		{
			"first": 194,
			"second": 85,
			"amount": 0
		},
		{
			"first": 194,
			"second": 86,
			"amount": -2
		},
		{
			"first": 194,
			"second": 87,
			"amount": -2
		},
		{
			"first": 194,
			"second": 89,
			"amount": -2
		},
		{
			"first": 196,
			"second": 67,
			"amount": -1
		},
		{
			"first": 196,
			"second": 71,
			"amount": -1
		},
		{
			"first": 196,
			"second": 74,
			"amount": 1
		},
		{
			"first": 196,
			"second": 79,
			"amount": -1
		},
		{
			"first": 196,
			"second": 81,
			"amount": -1
		},
		{
			"first": 196,
			"second": 83,
			"amount": 1
		},
		{
			"first": 196,
			"second": 84,
			"amount": -2
		},
		{
			"first": 196,
			"second": 85,
			"amount": 0
		},
		{
			"first": 196,
			"second": 86,
			"amount": -2
		},
		{
			"first": 196,
			"second": 87,
			"amount": -2
		},
		{
			"first": 196,
			"second": 89,
			"amount": -2
		},
		{
			"first": 200,
			"second": 65,
			"amount": -1
		},
		{
			"first": 200,
			"second": 67,
			"amount": -1
		},
		{
			"first": 200,
			"second": 71,
			"amount": -1
		},
		{
			"first": 200,
			"second": 74,
			"amount": 0
		},
		{
			"first": 200,
			"second": 79,
			"amount": -1
		},
		{
			"first": 200,
			"second": 81,
			"amount": -1
		},
		{
			"first": 200,
			"second": 84,
			"amount": 0
		},
		{
			"first": 201,
			"second": 65,
			"amount": -1
		},
		{
			"first": 201,
			"second": 67,
			"amount": -1
		},
		{
			"first": 201,
			"second": 71,
			"amount": -1
		},
		{
			"first": 201,
			"second": 74,
			"amount": 0
		},
		{
			"first": 201,
			"second": 79,
			"amount": -1
		},
		{
			"first": 201,
			"second": 81,
			"amount": -1
		},
		{
			"first": 201,
			"second": 84,
			"amount": 0
		},
		{
			"first": 202,
			"second": 65,
			"amount": -1
		},
		{
			"first": 202,
			"second": 67,
			"amount": -1
		},
		{
			"first": 202,
			"second": 71,
			"amount": -1
		},
		{
			"first": 202,
			"second": 74,
			"amount": 0
		},
		{
			"first": 202,
			"second": 79,
			"amount": -1
		},
		{
			"first": 202,
			"second": 81,
			"amount": -1
		},
		{
			"first": 202,
			"second": 84,
			"amount": 0
		},
		{
			"first": 212,
			"second": 65,
			"amount": -1
		},
		{
			"first": 212,
			"second": 77,
			"amount": 0
		},
		{
			"first": 212,
			"second": 84,
			"amount": -1
		},
		{
			"first": 212,
			"second": 86,
			"amount": -1
		},
		{
			"first": 212,
			"second": 87,
			"amount": -1
		},
		{
			"first": 212,
			"second": 88,
			"amount": -1
		},
		{
			"first": 212,
			"second": 89,
			"amount": -1
		},
		{
			"first": 212,
			"second": 90,
			"amount": -1
		},
		{
			"first": 217,
			"second": 65,
			"amount": 0
		},
		{
			"first": 217,
			"second": 88,
			"amount": 0
		},
		{
			"first": 219,
			"second": 65,
			"amount": 0
		},
		{
			"first": 219,
			"second": 88,
			"amount": 0
		},
		{
			"first": 220,
			"second": 65,
			"amount": 0
		},
		{
			"first": 220,
			"second": 88,
			"amount": 0
		},
		{
			"first": 47,
			"second": 47,
			"amount": -4
		}
	]
}
