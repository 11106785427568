import { EventDispatcher, Mesh, PerspectiveCamera, Raycaster, Vector2 } from 'three'

import { ChaletState } from '@/ui/components/scene/ChaletContainer'

export default class RayCaster extends EventDispatcher {
	private readonly caster: Raycaster = new Raycaster()
	private readonly targets: Mesh[] = []
	private hover: ChaletState | string = null
	private enabled = false

	enable(): void {
		this.enabled = true
	}

	disable(): void {
		this.enabled = false
	}

	setLayer(layer: number): void {
		this.caster.layers.set(layer)
	}

	setTarget(mesh: Mesh): void {
		this.targets.push(mesh)
	}

	update(
		pointer: Vector2,
		camera: PerspectiveCamera
	): { id: ChaletState | string; href: ChaletState | string; disabled: boolean } {
		if (!this.enabled) return null

		this.caster.setFromCamera(pointer, camera)
		const intersects = this.caster.intersectObjects(this.targets)
		const id = intersects[0] ? intersects[0].object.userData.id : null
		const href = intersects[0] ? intersects[0].object.userData.href : null
		const disabled = intersects[0] ? intersects[0].object.userData.disabled : false

		if (this.hover !== id && !disabled) {
			// @ts-ignore
			this.dispatchEvent({ type: 'over', id, href })
		}

		this.hover = id
		return { id, href, disabled }
	}
}
