precision highp float;
#define GLSLIFY 1

uniform sampler2D bg;
uniform sampler2D blur;
uniform float alpha;

varying vec2 vUv;

void main() {
	vec4 color = mix(texture2D(bg, vUv), texture2D(blur, vUv), alpha);
	gl_FragColor = LinearTosRGB(color);
}
