import {
	DoubleSide,
	Mesh,
	MeshBasicMaterial,
	Object3D,
	PerspectiveCamera,
	PlaneGeometry,
	PointLight,
	ShadowMaterial,
	Texture,
	Vector3
} from 'three'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import { COLORS } from '../../const'
import file from '../../gltf/indoor.glb'
import Animator from '../animator/Animator'
import Product from '../product/Product'
import Character from './Character'
import elements from './elements.jpg'
import envFall from './fall/env.jpg'
import furnitureFall from './fall/furniture.jpg'
import roomFall from './fall/room.jpg'
import tvFall from './fall/tv.jpg'
import IntroElement from './IntroElement'
import envSpring from './spring/env.jpg'
import furnitureSpring from './spring/furniture.jpg'
import roomSpring from './spring/room.jpg'
import tvSpring from './spring/tv.jpg'
import envSummer from './summer/env.jpg'
import furnitureSummer from './summer/furniture.jpg'
import roomSummer from './summer/room.jpg'
import tvSummer from './summer/tv.jpg'
import envWinter from './winter/env.jpg'
import furnitureWinter from './winter/furniture.jpg'
import roomWinter from './winter/room.jpg'
import tvWinter from './winter/tv.jpg'
import Chalet, { ChaletState, IntroElementType, LAYER } from '../Chalet'
import { Fire } from './Fire'
import Fade from '../fade/Fade'
import Button from '../button/Button'
import { Confetti } from '../confetti/Confetti'
import { loadTextures } from '../helper/loadTextures'

const indoorState = [
	'intro',
	'overview',
	'products',
	'events',
	'video',
	'library',
	'productview',
	'presentation',
	'anniversary'
] as const

type Options = {
	parent: Object3D
	camera: PerspectiveCamera
	loader: GLTFLoader
	chalet: Chalet
}
export type IndoorState = (typeof indoorState)[number]

export default class Indoor extends Animator {
	public isLoaded = false
	public isVisible = false
	public readonly products: Product[] = []
	public readonly introElements: IntroElement[] = []
	public readonly productsById: { product?: Product } = {}
	private readonly chalet: Chalet

	private readonly object: Object3D = new Object3D()
	private readonly camera: PerspectiveCamera
	private readonly parent: Object3D
	private readonly character: Character
	private readonly fire: Fire
	private readonly fade: Fade
	private readonly loader: GLTFLoader

	private cameraPositions: { [key in IndoorState]?: Vector3 } = {}
	private cameraTargets: { [key in IndoorState]?: Vector3 } = {}
	private centers: { [key in IndoorState]?: Vector3 } = {}
	private newsButton: Button
	private loadingPromise: Promise<Awaited<[GLTF, Texture[], void, void]>>
	private confetti: Confetti

	constructor({ parent, camera, loader, chalet }: Options) {
		super()
		parent.add(this.object)

		this.parent = parent
		this.camera = camera
		this.loader = loader
		this.chalet = chalet

		this.object.visible = false
		this.character = new Character(this.object, chalet)
		this.fire = new Fire(this.object, chalet)
		this.fade = new Fade(COLORS.black, chalet)
		this.confetti = new Confetti(this.object, chalet)

		// @ts-ignore
		this.chalet.raycaster.addEventListener('over', ({ id }) => this.onOver(id))
	}

	show(): void {
		this.character.show()
		this.isVisible = true
		this.object.visible = true
		this.chalet.composer.setShadowMap(true)
	}

	hide(): void {
		this.character.hide()
		this.isVisible = false
		this.object.visible = false
		this.confetti.hide()
		this.chalet.composer.setShadowMap(false)
	}

	async load(): Promise<void> {
		if (!this.loadingPromise) {
			this.loadingPromise = Promise.all([
				this.loadObject(file),
				this.loadTexture(),
				this.loadProducts(),
				this.character.load()
			])
		}

		const [gltf, textures] = await this.loadingPromise

		if (!this.isLoaded) {
			this.init(gltf, textures)
			this.isLoaded = true
		}
	}

	navigate(state: ChaletState): {
		cameraPosition: Vector3
		cameraTarget: Vector3
	} {
		if (!this.isLoaded) return
		if (!this.isVisible) return

		this.confetti.show()
		this.character.showAnimation()

		return {
			cameraPosition: this.cameraPositions[state],
			cameraTarget: this.cameraTargets[state]
		}
	}

	showIntroElement(element: string): void {
		this.introElements.forEach((el) => (el.name === element ? el.show() : el.hide()))
	}

	setIntroTalking(): void {}

	init(gltf: GLTF, textures: Texture[]): void {
		const { scene } = gltf

		this.object.add(scene)

		const elementsMaterial = new MeshBasicMaterial({ map: textures[3] })

		scene.traverse((child) => {
			switch (true) {
				case child.name.includes('camera_'): {
					child.visible = false
					const name: IndoorState = child.name.replace('camera_', '') as IndoorState

					this.cameraPositions[name] = child.position.clone()
					break
				}

				case child.name.includes('target_'): {
					child.visible = false

					const name: IndoorState = child.name.replace('target_', '') as IndoorState

					this.cameraTargets[name] = child.position.clone()
					break
				}

				case child.name === 'news_button': {
					child.visible = false
					// this.newsButton = new Button({
					// 	id: 'news_button',
					// 	href: 'https://www.linkedin.com/company/biomed-ag/',
					// 	size: 0.35,
					// 	img: linkedIn,
					// 	parent: this.object,
					// 	position: child.position,
					// 	quaternion: child.quaternion,
					// 	layers: [LAYER.events]
					// })
					// this.chalet.raycaster.setTarget(this.newsButton.mesh)
					break
				}

				case child.name === 'furniture': {
					const mesh = child as Mesh
					mesh.material = new MeshBasicMaterial({ map: textures[0] })
					mesh.material.side = DoubleSide
					break
				}

				case child.name === 'room': {
					const mesh = child as Mesh
					mesh.material = new MeshBasicMaterial({ map: textures[1] })
					break
				}

				case child.name === 'tv': {
					const mesh = child as Mesh
					mesh.material = new MeshBasicMaterial({ map: textures[2] })
					break
				}

				case child.name === 'environment': {
					const mesh = child as Mesh
					mesh.material = new MeshBasicMaterial({ map: textures[4] })
					break
				}

				case child.name === 'fire': {
					child.visible = false
					this.fire.setPosition(child.position)
					break
				}

				// case child.name === 'screen': {
				// 	this.screen = new Screen(child as Mesh, this.chalet)
				// 	break
				// }

				case child.parent.name === 'elements': {
					const mesh = child as Mesh
					mesh.material = elementsMaterial

					this.introElements.push(new IntroElement(mesh, mesh.name as IntroElementType, this.chalet))
					break
				}

				case child.name === 'newspaper_bounding_box': {
					child.visible = false

					// child.userData.id = 'newspaper_bounding_box'
					// child.userData.href = 'https://www.linkedin.com/company/biomed-ag/'
					// child.layers.enable(LAYER.overview)
					// child.layers.enable(LAYER.events)
					// child.layers.enable(LAYER.intro)
					// child.layers.enable(LAYER.products)
					// this.chalet.raycaster.setTarget(child as Mesh)
					break
				}

				case child.name === 'character_bounding_box': {
					child.visible = false

					child.userData.id = 'character_bounding_box'
					child.userData.href = 'intro'
					child.layers.enable(LAYER.products)
					this.chalet.raycaster.setTarget(child as Mesh)
					break
				}

				case child.name === 'tv_bounding_box': {
					child.visible = false

					// if (category === 'rx-ad') return

					child.userData.id = 'tv_bounding_box'
					child.userData.href = 'events'
					child.layers.enable(LAYER.overview)
					child.layers.enable(LAYER.intro)
					child.layers.enable(LAYER.products)
					this.chalet.raycaster.setTarget(child as Mesh)
					break
				}

				case child.name === 'antlers_bounding_box': {
					child.visible = false

					// child.userData.id = 'antlers_bounding_box'
					// child.userData.href = 'outro'
					// child.layers.enable(LAYER.overview)
					// child.layers.enable(LAYER.products)
					// this.chalet.raycaster.setTarget(child as Mesh)
					break
				}
			}
		})

		const light = new PointLight(0xffffff, 1.5)
		light.position.set(0, 4, 0)
		light.castShadow = true
		light.shadow.camera.near = 0.2
		light.shadow.camera.far = 15
		light.shadow.mapSize.width = 2048
		light.shadow.mapSize.height = 2048

		this.object.add(light)

		const planeGeometry = new PlaneGeometry(20, 20)
		planeGeometry.rotateX(Math.PI * -0.5)
		const planeMaterial = new ShadowMaterial({ color: 0x060538, opacity: 0.25 })
		// const planeMaterial = new MeshBasicMaterial({ color: 'red' })
		const plane = new Mesh(planeGeometry, planeMaterial)
		plane.position.y = 0.01
		plane.receiveShadow = true
		this.object.add(plane)

		Object.keys(this.cameraPositions).forEach((key) => {
			const dir = this.cameraPositions[key].clone().sub(this.cameraTargets[key])
			this.centers[key] = this.cameraPositions[key].clone().sub(dir.multiplyScalar(0.5))
		})

		this.cameraPositions['productview'] = this.cameraPositions['products'].clone()
		this.cameraTargets['productview'] = this.cameraTargets['products'].clone()
		this.centers['productview'] = this.centers['products'].clone()

		this.cameraPositions['presentation'] = this.cameraPositions['products'].clone()
		this.cameraTargets['presentation'] = this.cameraTargets['products'].clone()
		this.centers['presentation'] = this.centers['products'].clone()

		this.cameraPositions['anniversary'] = this.cameraPositions['overview'].clone()
		this.cameraTargets['anniversary'] = this.cameraTargets['overview'].clone()
		this.centers['anniversary'] = this.centers['overview'].clone()

		this.setLocale()
	}

	async loadTexture(): Promise<Texture[]> {
		let furniture: string
		let room: string
		let tv: string
		let env: string

		switch (this.chalet.season) {
			case 'spring': {
				furniture = furnitureSpring
				room = roomSpring
				tv = tvSpring
				env = envSpring
				break
			}
			case 'summer': {
				furniture = furnitureSummer
				room = roomSummer
				tv = tvSummer
				env = envSummer
				break
			}
			case 'fall': {
				furniture = furnitureFall
				room = roomFall
				tv = tvFall
				env = envFall
				break
			}
			default:
				furniture = furnitureWinter
				room = roomWinter
				tv = tvWinter
				env = envWinter
		}

		return loadTextures([furniture, room, tv, elements, env])
	}

	async loadProducts(): Promise<void> {
		return
		// const disabledProducts = JSON.parse(localStorage.getItem('disabledProducts'))
		//
		// let file
		//
		// switch (category) {
		// 	case 'otc':
		// 		file = fileOtc
		// 		break
		// 	case 'rx':
		// 		file = fileRx
		// 		break
		// 	case 'rx-ad':
		// 		file = fileRxAd
		// 		break
		// }
		//
		// const gltf = await this.loadObject(file)
		// const { scene } = gltf
		//
		// this.object.add(scene)
		//
		// scene.traverse((child) => {
		// 	if ((child as Mesh).isMesh) {
		// 		const name = child.name.replaceAll('_', '-')
		// 		const disabled = category === 'rx-ad' && disabledProducts?.[name]
		// 		const product = new Product(child as Mesh, this.chalet, disabled)
		// 		this.products.push(product)
		// 		this.productsById[product.id] = product
		// 		this.chalet.raycaster.setTarget(child as Mesh)
		// 	}
		// })
	}

	async loadObject(file: string): Promise<GLTF> {
		return await this.loader.loadAsync(file)
	}

	disableProduct(id: string): void {
		const product = this.products.find((product) => product.id === id)
		product.toggleDisabledState()

		const disabledProducts = this.products.reduce((disabledProducts, product) => {
			disabledProducts[product.name] = product.disabled
			return disabledProducts
		}, {})

		localStorage.setItem('disabledProducts', JSON.stringify(disabledProducts))
	}

	onOver(id: ChaletState | string): void {
		if (!this.isVisible) return
		if (!this.isLoaded) return
		id === this.newsButton?.id ? this.newsButton?.onOver() : this.newsButton?.onOut()
		this.productsById[id]?.onOver()
	}

	getProduct(id: string): Product {
		return this.productsById[id]
	}

	setLocale(): void {}

	snap(center: Vector3): IndoorState | null {
		return null
		// if (!this.isVisible) return null
		// if (!this.isLoaded) return null
		//
		// if (this.chalet.state === 'anniversary') return null
		//
		// const dist = Object.values(this.centers).map((c) => center.distanceToSquared(c))
		// const newState = Object.keys(this.centers)[dist.indexOf(Math.min(...dist))] as IndoorState
		//
		// if (newState === 'anniversary') return 'overview'
		// if (newState === 'video') return 'events'
		//
		// return newState
	}

	update(delta: number, time: number, cameraWorldPosition: Vector3): void {
		if (!this.isVisible) return
		if (!this.isLoaded) return

		this.character.update(delta)
		this.fire.update(time, cameraWorldPosition)
		this.confetti.update(time)
		this.introElements.forEach((element) => element.update(time))
	}
}
