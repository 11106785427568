import { MathUtils, PerspectiveCamera, Vector2 } from 'three'

import Animator from '../animator/Animator'
import Chalet from '../Chalet'
import ProductControls from './ProductControls'
import Product from '../product/Product'

type Options = {
	domElement: HTMLElement
	chalet: Chalet
}

export default class ProductView extends Animator {
	public isVisible = false

	private readonly chalet: Chalet
	private readonly rotation: Vector2 = new Vector2()
	private readonly rotationOffset: Vector2 = new Vector2()
	private readonly productControls: ProductControls

	private product: Product
	private isHiding = false
	private isShowing = false
	private width: number
	private height: number

	constructor({ domElement, chalet }: Options) {
		super()
		this.chalet = chalet
		this.productControls = new ProductControls(domElement, this)
	}

	async show(product: Product, camera: PerspectiveCamera): Promise<void> {
		this.stopAnimations()

		this.isShowing = true
		this.isVisible = true
		this.isHiding = false

		this.rotation.set(0, 0)
		this.rotationOffset.set(0, 0)

		this.product = product
		await this.product.open(camera)

		this.productControls.start()
		this.isShowing = false
	}

	async hide(): Promise<void> {
		if (!this.isVisible) return
		if (this.isHiding) return
		this.isHiding = true

		this.productControls.stop()
		this.stopAnimations()

		if (this.product) {
			await this.product.close()
		}

		this.product = undefined
		this.isVisible = false
	}

	resize(width: number, height: number, dpr: number, camera: PerspectiveCamera): void {
		this.width = width
		this.height = height

		if (this.product) {
			this.product.resize(camera)
		}
	}

	onUpdate(rotation: Vector2): void {
		this.product.setRotation(MathUtils.degToRad(rotation.y * 0.25), MathUtils.degToRad(rotation.x * 0.25), 0)
		this.chalet.composer.requestRender()
	}

	update(delta: number): void {
		if (!this.isVisible) return
		if (this.isHiding) return
		this.productControls.update(delta)
	}
}
