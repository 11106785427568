import { CanvasTexture, ImageBitmapLoader, SRGBColorSpace, Texture, TextureLoader } from 'three'

export const loadTextures = async (files: string[]): Promise<Texture[]> => {
	//https://github.com/mrdoob/three.js/blob/dev/examples/jsm/loaders/GLTFLoader.js#L2410
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) === true
	const isFirefox = navigator.userAgent.indexOf('Firefox') > -1
	const firefoxVersion = isFirefox ? navigator.userAgent.match(/Firefox\/([0-9]+)\./)[1] : '-1'

	if (typeof createImageBitmap === 'undefined' || isSafari || (isFirefox && parseFloat(firefoxVersion) < 98)) {
		const loader = new TextureLoader()
		const textures = await Promise.all(files.map((file) => loader.loadAsync(file)))
		textures.forEach((tex) => {
			tex.colorSpace = SRGBColorSpace
			tex.flipY = false
		})
		return textures
	} else {
		const loader = new ImageBitmapLoader()
		const images = await Promise.all(files.map((file) => loader.loadAsync(file)))
		const textures = images.map((image) => new CanvasTexture(image))
		textures.forEach((tex) => {
			tex.colorSpace = SRGBColorSpace
		})
		return textures
	}
}
