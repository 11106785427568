import { ChaletSeason } from './scene/Chalet'

const BASE_FONT = 16.0

export function px2Em(pixel: number): string {
	return `${pixel / BASE_FONT}em`
}

export function px2Rem(pixel: number): string {
	return `${pixel / BASE_FONT}rem`
}

type P2VUnits = 'vw' | 'vh' | 'vmin' | 'vmax'
export function p2v(px: number, screen = 1920.0, unit: P2VUnits = 'vw'): string | undefined {
	const validUnits = ['vw', 'vh', 'vmin', 'vmax']
	const isValidUnit = validUnits.includes(unit)

	if (!isValidUnit) {
		console.warn(`Unknown unit '${unit}'.`)
		return
	}

	return `${(100 / screen) * px}${unit}`
}

export const GUTTER = `${p2v(30)}`
export const HALF_GUTTER = `${p2v(15)}`
export const MOBILE_GUTTER = `5vw`

export const SIZE_TABLET = 768
export const SIZE_DESKTOP_SMALL = 1024
export const SIZE_DESKTOP = 1280
export const SIZE_DESKTOP_LARGE = 1440
export const SIZE_DESKTOP_XTRA_LARGE = 1920

export const MQ_TABLET = `${px2Em(SIZE_TABLET)}`
export const MQ_DESKTOP_SMALL = `${px2Em(SIZE_DESKTOP_SMALL)}`
export const MQ_DESKTOP = `${px2Em(SIZE_DESKTOP)}`
export const MQ_DESKTOP_LARGE = `${px2Em(SIZE_DESKTOP_LARGE)}`
export const MQ_DESKTOP_XTRA_LARGE = `${px2Em(SIZE_DESKTOP_XTRA_LARGE)}`

/* COLORS ====================================================================*/

export const COLORS = {
	backgroundWinter: '#76b2ff',
	backgroundSpring: '#AAD870',
	biomedBlue: '#0079BC',
	blueDark: '#003B61',
	blueDark50: 'rgba(0,59,97,0.5)',
	white: '#fff',
	black: '#000',
	blackIntense: '#2B2B2B',
	rainyAfternoonMood: '#F2F5F7',
	rainyAfternoonMoodHover: '#e6ebef',
	surface: 'rgba(255, 255, 255, 0.1)',
	red: '#D80000',

	grey5: '#E0E0E0', // ¯\_(ツ)_/¯
	bgPlaceholder: '#D3D8E1',

	green: '#27ae60'
}

export const BACKGROUND_COLORS: { [key in ChaletSeason]: string } = {
	spring: '#AAD870',
	summer: '#4C9349',
	fall: '#676652',
	winter: '#76b2ff'
}

export const SEASON: ChaletSeason = 'spring'
