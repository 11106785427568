precision highp float;
#define GLSLIFY 1

uniform sampler2D map;
uniform vec3 color;
uniform float fade;
uniform float alpha;

varying vec2 vUv;
varying float vDissolve;

void main() {
	float f = fade * min(vDissolve * 1.25, 1.0);
	float a = texture2D(map, vUv).a;
	float s = smoothstep(vDissolve - f, vDissolve + f, a);
	float o = 1.0 - max(vDissolve - 0.9, 0.0) * 10.0;

	gl_FragColor = vec4(color, s * o * alpha);
}
