import Chalet from './scene/Chalet'
import { SEASON } from './const'

const init = () => {
	const container = document.querySelector('.container') as HTMLCanvasElement
	const title = document.querySelector('h1') as HTMLElement

	const onCursorChange = (type: string) => {
		container.style.setProperty('cursor', type)
	}

	const chalet = new Chalet(
		container,
		SEASON,
		onCursorChange,
		() => void 0,
		() => void 0
	)

	const onNavigate = (href: string): void => {
		console.log(href)
		try {
			const url = new URL(href)
			window.open(url.href, '_blank')
		} catch (error) {
			const route = `/${href}`
			window.history.pushState({}, '', route)
			navigate(route)
		}
	}

	const navigate = (pathname: string) => {
		switch (pathname) {
			case '/overview': {
				chalet.navigate('overview')
				title.style.opacity = '1'
				break
			}
			default: {
				chalet.navigate('index')
				title.style.opacity = '0'
			}
		}
	}

	chalet.setOnNavigate(onNavigate)
	window.addEventListener('popstate', () => navigate(window.location.pathname))
	navigate(window.location.pathname)
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', () => init()) : init()
