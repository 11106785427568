import { Tween } from '@tweenjs/tween.js'
import {
	Color,
	DoubleSide,
	DynamicDrawUsage,
	Group,
	InstancedBufferAttribute,
	InstancedMesh,
	Object3D,
	PlaneGeometry,
	RawShaderMaterial,
	TextureLoader,
	Vector3
} from 'three'

import fragmentShader from './confetti.frag'
import map from './confetti.png'
import vertexShader from './confetti.vert'
import Chalet from '../Chalet'

const COLORS = [
	new Color('#124D78'),
	new Color('#F2C44C'),
	new Color('#DEEDFF'),
	new Color('#FFFFFF'),
	new Color('#124D78'),
	new Color('#6DB250')
]

export class Confetti {
	private readonly object: Group = new Group()
	private readonly mesh: InstancedMesh<PlaneGeometry, RawShaderMaterial>
	private readonly count = 2000
	private direction = new Vector3()
	private tween: Tween<{ elapsed: number }>
	private needsUpdate = false
	private enabled = false

	constructor(
		parent: Object3D,
		private readonly chalet: Chalet
	) {
		const geometry = new PlaneGeometry(1, 1)
		const random1 = new Float32Array(this.count).fill(0).map(() => Math.random())
		const random2 = new Float32Array(this.count).fill(0).map(() => Math.random())
		const random3 = new Float32Array(this.count).fill(0).map(() => Math.random())
		const random4 = new Float32Array(this.count).fill(0).map(() => Math.random())
		const color = new Float32Array(this.count * 3)

		const colors = COLORS.map((c) => c.convertLinearToSRGB().toArray())

		for (let i = 0; i < this.count; i++) {
			const c = colors[Math.floor(colors.length * Math.random())]
			color[i * 3] = c[0]
			color[i * 3 + 1] = c[1]
			color[i * 3 + 2] = c[2]
		}

		geometry.setAttribute('random1', new InstancedBufferAttribute(random1, 1, true, 1))
		geometry.setAttribute('random2', new InstancedBufferAttribute(random2, 1, true, 1))
		geometry.setAttribute('random3', new InstancedBufferAttribute(random3, 1, true, 1))
		geometry.setAttribute('random4', new InstancedBufferAttribute(random4, 1, true, 1))
		geometry.setAttribute('color', new InstancedBufferAttribute(color, 3, true, 1))

		const material = new RawShaderMaterial({
			vertexShader,
			fragmentShader,
			side: DoubleSide,
			transparent: true,
			depthWrite: false,
			uniforms: {
				map: { value: new TextureLoader().load(map) },
				time: { value: performance.now() },
				color: { value: new Color(0xffffff) },
				scale: { value: 0.1 },
				elapsed: { value: -2 },
				dimensions: { value: new Vector3(1, 1, 1) }
			}
		})

		this.mesh = new InstancedMesh(geometry, material, this.count)
		this.mesh.instanceMatrix.setUsage(DynamicDrawUsage)

		this.object.visible = false
		this.object.add(this.mesh)
		parent.add(this.object)
	}

	async show() {
		this.enabled = true
		this.animate()
	}

	hide() {
		this.enabled = false
		this.object.visible = false
		this.tween?.stop()
	}

	async animate() {
		this.tween?.stop()
		this.tween = new Tween({ elapsed: -2 })
			.delay(250)
			.onStart(() => {
				this.needsUpdate = true
			})
			.to({ elapsed: 2 }, 15_000)
			.onUpdate(({ elapsed }) => {
				this.mesh.material.uniforms.elapsed.value = elapsed
			})
			.onComplete(() => this.hide())
			.start()
	}

	update(time: number): void {
		if (!this.enabled) return

		if (this.needsUpdate) {
			this.needsUpdate = false

			const zoom = this.chalet.camera.zoom
			const vFov = ((3 / zoom) * this.chalet.camera.getFilmHeight()) / this.chalet.camera.getFocalLength()
			const hFov = vFov * this.chalet.camera.aspect
			const min = Math.min(vFov, hFov)
			const scale = min * 0.025

			this.direction.copy(this.chalet.cameraWorldDirection).multiplyScalar(3)
			this.object.position.copy(this.chalet.cameraWorldPosition)
			this.object.position.add(this.direction)
			this.object.lookAt(this.chalet.cameraWorldPosition)

			this.mesh.material.uniforms.dimensions.value.set(hFov * 1.2, vFov * 1.1, min)
			this.mesh.material.uniforms.scale.value = scale

			this.object.visible = true
		}

		this.mesh.material.uniforms.time.value = time
	}
}
