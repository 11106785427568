import { Mesh, MeshBasicMaterial, Object3D, Texture, Vector3 } from 'three'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import file from '../../gltf/outdoor.glb'
import detailsFall from './fall/details.jpg'
import envFall from './fall/env.jpg'
import facadeFall from './fall/facade.jpg'
import roofFall from './fall/roof.jpg'
import detailsSpring from './spring/details.jpg'
import envSpring from './spring/env.jpg'
import facadeSpring from './spring/facade.jpg'
import roofSpring from './spring/roof.jpg'
import detailsSummer from './summer/details.jpg'
import envSummer from './summer/env.jpg'
import facadeSummer from './summer/facade.jpg'
import roofSummer from './summer/roof.jpg'
import detailsWinter from './winter/details.jpg'
import envWinter from './winter/env.jpg'
import facadeWinter from './winter/facade.jpg'
import roofWinter from './winter/roof.jpg'
import Chalet, { ChaletState, LABELS, LAYER } from '../Chalet'
import Label from '../label/Label'
import { Smoke } from '../smoke/Smoke'
import { loadTextures } from '../helper/loadTextures'

type Options = {
	parent: Object3D
	loader: GLTFLoader
	chalet: Chalet
}

export default class Outdoor {
	private readonly loader: GLTFLoader
	private readonly chalet: Chalet
	private readonly object: Object3D = new Object3D()

	private isLoaded = false
	private isVisible = false
	private cameraPosition: Vector3
	private cameraTarget: Vector3
	private label: Label
	private smoke: Smoke
	private loadingPromise: Promise<Awaited<[GLTF, Texture[]]>>

	constructor({ parent, loader, chalet }: Options) {
		this.loader = loader
		this.chalet = chalet
		this.object.visible = false

		this.smoke = new Smoke(this.object)

		parent.add(this.object)
		// @ts-ignore
		this.chalet.raycaster.addEventListener('over', ({ id }) => this.onOver(id))
	}

	show(): void {
		this.isVisible = true
		this.object.visible = true
		this.label.show()
	}

	hide(): void {
		this.isVisible = false
		this.object.visible = false
		this.label.reset()
	}

	async load(): Promise<void> {
		if (!this.isLoaded) {
			this.loadingPromise = Promise.all([this.loadModel(), this.loadTexture()])
		}

		const [gltf, textures] = await this.loadingPromise

		if (!this.isLoaded) {
			this.init(gltf, textures)
			this.isLoaded = true
		}
	}

	navigate(): { cameraPosition: Vector3; cameraTarget: Vector3 } {
		const target = this.cameraTarget.clone()

		return {
			cameraPosition: this.cameraPosition,
			cameraTarget: target
		}
	}

	init(gltf: GLTF, textures: Texture[]): void {
		const { scene } = gltf
		this.object.add(scene)
		scene.traverse((child) => {
			switch (child.name) {
				case 'facade': {
					const mesh = child as Mesh
					mesh.material = new MeshBasicMaterial({ map: textures[0] })
					break
				}
				case 'roof': {
					const mesh = child as Mesh
					mesh.material = new MeshBasicMaterial({ map: textures[1] })
					break
				}
				case 'details': {
					const mesh = child as Mesh
					mesh.material = new MeshBasicMaterial({ map: textures[2] })
					break
				}
				case 'env': {
					const mesh = child as Mesh
					mesh.material = new MeshBasicMaterial({ map: textures[3] })
					break
				}
				case 'target_outdoor':
					this.cameraTarget = child.position
					break
				case 'camera_outdoor':
					this.cameraPosition = child.position
					break
				case 'smoke':
					child.visible = false
					this.smoke.setPosition(child.position)
					break
				case 'label':
					child.visible = false
					this.label = new Label({
						id: 'overview',
						href: 'overview',
						parent: this.object,
						position: child.position,
						quaternion: child.quaternion,
						size: 1.25,
						layers: [LAYER.index]
					})
					this.chalet.raycaster.setTarget(this.label.mesh)
					break
			}
		})

		this.setLocale()
	}

	async loadTexture(): Promise<Texture[]> {
		let facade: string
		let roof: string
		let details: string
		let env: string

		switch (this.chalet.season) {
			case 'spring':
				facade = facadeSpring
				roof = roofSpring
				details = detailsSpring
				env = envSpring
				break
			case 'summer':
				facade = facadeSummer
				roof = roofSummer
				details = detailsSummer
				env = envSummer
				break
			case 'fall':
				facade = facadeFall
				roof = roofFall
				details = detailsFall
				env = envFall
				break
			default:
				facade = facadeWinter
				roof = roofWinter
				details = detailsWinter
				env = envWinter
		}

		return loadTextures([facade, roof, details, env])
	}

	async loadModel(): Promise<GLTF> {
		return await this.loader.loadAsync(file)
	}

	onOver(id: ChaletState): void {
		if (!this.isVisible) return
		if (!this.isLoaded) return
		this.label.id === id ? this.label.onOver() : this.label.onOut()
	}

	setLocale(): void {
		this.label?.setText(LABELS.labelOverview)
	}

	snap(): null {
		return null
	}

	update(delta: number, time: number, lookAt: Vector3): void {
		if (!this.isVisible) return
		if (!this.isLoaded) return
		this.smoke.update(time, lookAt)
		this.chalet.composer.requestRender()
	}
}
