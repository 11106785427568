import {
	Color,
	Mesh,
	PCFSoftShadowMap,
	PlaneGeometry,
	ShaderMaterial,
	SRGBColorSpace,
	Vector2,
	WebGLRenderer,
	WebGLRenderTarget
} from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { TexturePass } from 'three/examples/jsm/postprocessing/TexturePass.js'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js'

import bgFragmentShader from './bg.frag'
import bgVertexShader from './bg.vert'
import blurFragmentShader from './blur.frag'
import blurVertexShader from './blur.vert'
import Animator from '../animator/Animator'
import Fade from '../fade/Fade'
import Chalet, { PRODUCT_LAYER } from '../Chalet'
import { BACKGROUND_COLORS } from '../../const'

const BACKGROUND_BLUR = 0.02

export type Mode = 'default' | 'blur'

export default class Composer extends Animator {
	private readonly blurComposer: EffectComposer
	private readonly blurResolution: Vector2 = new Vector2()
	private readonly bg: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly renderTarget: WebGLRenderTarget

	private blurAlpha = 0
	private mode: Mode
	private fade: Fade

	public readonly renderer: WebGLRenderer
	public needsRenderUpdate = false

	constructor(private readonly chalet: Chalet) {
		super()

		this.fade = new Fade(BACKGROUND_COLORS[this.chalet.season], chalet)

		this.renderer = new WebGLRenderer({
			antialias: true,
			powerPreference: 'high-performance',
		})

		this.renderer.setPixelRatio(window.devicePixelRatio)
		this.renderer.setClearColor(new Color(BACKGROUND_COLORS[this.chalet.season]))
		this.renderer.outputColorSpace = SRGBColorSpace
		this.renderer.shadowMap.type = PCFSoftShadowMap

		chalet.domElement.appendChild(this.renderer.domElement)

		this.renderTarget = new WebGLRenderTarget(1, 1, { samples: this.isWebGL2 ? 2 : 0 })

		this.blurComposer = new EffectComposer(this.renderer)
		this.blurComposer.renderToScreen = false
		this.blurComposer.setPixelRatio(window.devicePixelRatio)
		this.blurComposer.addPass(new TexturePass(this.renderTarget.texture))
		this.blurComposer.addPass(
			new ShaderPass(
				new ShaderMaterial({
					uniforms: {
						baseTexture: { value: null },
						delta: { value: new Vector2(BACKGROUND_BLUR, 0) },
						resolution: { value: this.blurResolution }
					},
					fragmentShader: blurFragmentShader,
					vertexShader: blurVertexShader
				}),
				'baseTexture'
			)
		)
		this.blurComposer.addPass(
			new ShaderPass(
				new ShaderMaterial({
					uniforms: {
						baseTexture: { value: null },
						delta: { value: new Vector2(0, BACKGROUND_BLUR) },
						resolution: { value: this.blurResolution }
					},
					fragmentShader: blurFragmentShader,
					vertexShader: blurVertexShader
				}),
				'baseTexture'
			)
		)
		this.blurComposer.addPass(new ShaderPass(CopyShader))

		const bgMaterial = new ShaderMaterial({
			vertexShader: bgVertexShader,
			fragmentShader: bgFragmentShader,
			depthTest: false,
			uniforms: {
				alpha: { value: this.blurAlpha },
				bg: { value: this.renderTarget.texture },
				blur: { value: this.blurComposer.readBuffer.texture }
			}
		})

		this.bg = new Mesh(new PlaneGeometry(2, 2), bgMaterial)
		this.bg.frustumCulled = false
		this.bg.layers.disable(PRODUCT_LAYER.hidden)
		this.bg.layers.enable(PRODUCT_LAYER.visible)
		this.chalet.scene.add(this.bg)
	}

	get isWebGL2(): boolean {
		return this.renderer.capabilities.isWebGL2
	}

	async setMode(mode: Mode): Promise<void> {
		if (this.mode === mode) {
			return
		}

		if (this.mode === 'blur') {
			await this.fadeOutBlur()
		}

		this.mode = mode

		switch (mode) {
			case 'blur': {
				this.renderBlur()

				break
			}

			default: {
				this.chalet.camera.layers.enable(PRODUCT_LAYER.hidden)
				this.chalet.camera.layers.disable(PRODUCT_LAYER.visible)

				this.renderer.render(this.chalet.scene, this.chalet.camera)

				break
			}
		}
	}

	renderBlur(): void {
		//hide active product and bg, show everything else
		this.chalet.camera.layers.enable(PRODUCT_LAYER.hidden)
		this.chalet.camera.layers.disable(PRODUCT_LAYER.active)
		this.chalet.camera.layers.disable(PRODUCT_LAYER.visible)

		// render and blur bg texture
		this.renderer.setRenderTarget(this.renderTarget)
		this.renderer.render(this.chalet.scene, this.chalet.camera)
		this.renderer.setRenderTarget(null)
		this.blurComposer.render()

		// show bg and active product, hide everything else
		this.chalet.camera.layers.disable(PRODUCT_LAYER.hidden)
		this.chalet.camera.layers.enable(PRODUCT_LAYER.active)
		this.chalet.camera.layers.enable(PRODUCT_LAYER.visible)
	}

	async showFade(): Promise<void> {
		await this.fade.show()
	}

	async hideFade(): Promise<void> {
		await this.fade.hide()
	}

	async fadeInBlur(): Promise<void> {
		this.stopAnimations()

		await this.animate(
			{ alpha: this.blurAlpha },
			{ alpha: 1 },
			{
				duration: 500,
				onUpdate: ({ alpha }) => {
					this.blurAlpha = alpha
					this.bg.material.uniforms.alpha.value = alpha
					this.needsRenderUpdate = true
				}
			}
		)
	}

	async fadeOutBlur(): Promise<void> {
		this.stopAnimations()

		await this.animate(
			{ alpha: this.blurAlpha },
			{ alpha: 0 },
			{
				duration: 250,
				onUpdate: ({ alpha }) => {
					this.blurAlpha = alpha
					this.bg.material.uniforms.alpha.value = alpha
					this.needsRenderUpdate = true
				}
			}
		)
	}

	setShadowMap(shadow: boolean): void {
		this.renderer.shadowMap.enabled = shadow
	}

	requestRender(): void {
		this.needsRenderUpdate = true
	}

	resize(width: number, height: number, dpr: number): void {
		this.renderer.setSize(width, height)
		this.blurComposer.setSize(width, height)
		this.renderTarget.setSize(width * dpr, height * dpr)
		this.blurResolution.set(width * dpr, height * dpr)

		if (this.mode === 'blur') {
			this.renderBlur()
		}
	}

	update(): void {
		if (this.needsRenderUpdate) {
			this.needsRenderUpdate = false
			this.renderer.render(this.chalet.scene, this.chalet.camera)
		}
	}
}
