import { AnimationAction, AnimationClip, AnimationMixer, LoopRepeat, Object3D } from 'three'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import fileRx from '../../gltf/character.glb'
import Chalet from '../Chalet'

type AnimationType = 'dance'

export default class Character {
	private object: Object3D = new Object3D()
	private mixer: AnimationMixer
	private actions: { action?: AnimationAction } = {}
	private type: AnimationType | null = null
	private needsAnimationUpdate: boolean
	private isLoaded = false
	private isRunning = false

	constructor(
		parent: Object3D,
		private readonly chalet: Chalet
	) {
		parent.add(this.object)
		this.object.visible = false
	}

	show(): void {
		if (!this.isLoaded) return
		this.object.visible = true
	}

	hide(): void {
		if (!this.isLoaded) return
		this.object.visible = false
		this.mixer.stopAllAction()
	}

	showAnimation(): void {
		this.actions['dance']?.reset().setEffectiveTimeScale(1).setEffectiveWeight(1).fadeIn(0.5).play()
	}

	setAnimation(): void {
		this.isRunning = true
		this.actions[this.type].reset().setEffectiveTimeScale(1).setEffectiveWeight(1).fadeIn(0.5).play()
	}

	async load(): Promise<void> {
		const gltf = await this.loadObject()
		const { scene, animations } = gltf

		scene.traverse((child) => {
			if (child.type === 'SkinnedMesh') {
				child.frustumCulled = false
				child.castShadow = true
			}
		})

		this.mixer = new AnimationMixer(scene)

		this.actions['dance'] = this.mixer.clipAction(AnimationClip.findByName(animations, 'dance'))

		this.actions['dance'].setLoop(LoopRepeat)

		this.mixer.addEventListener('finished', () => (this.isRunning = false))

		this.object.add(scene)
		this.isLoaded = true
	}

	async loadObject(): Promise<GLTF> {
		const loader = new GLTFLoader()
		return await loader.loadAsync(fileRx)
	}

	update(delta: number): void {
		if (!this.isLoaded) return

		if (this.needsAnimationUpdate) {
			this.needsAnimationUpdate = false
			this.setAnimation()
		}

		this.mixer?.update(delta)

		if (this.isRunning) {
			this.chalet.composer.requestRender()
		}
	}
}
