import { Easing } from '@tweenjs/tween.js'
import { Euler, MathUtils, Vector2, Vector3 } from 'three'

import Animator from '../animator/Animator'
import getDistance2d from '../helper/getDistance2d'
import Chalet from '../Chalet'

const WHEEL_SPEED = 0.0005
const ARROW_ZOOM_SPEED = 0.05
const PAN_DAMPING = 0.2
const PAN_SPEED = 0.35
const PAN_THRESHOLD = 0.0001
const ROTATE_DAMPING = 0.075
const ROTATE_SPEED = 0.1
const ROTATE_THRESHOLD = 0.00001
const LINE_HEIGHT = 40 //https://stackoverflow.com/questions/20110224/what-is-the-height-of-a-line-in-a-wheel-event-deltamode-dom-delta-line
const LONG_PRESS_DURATION = 500

export default class Controls {
	public isDragging = false

	public readonly pointer: Vector2 = new Vector2()

	private readonly target: Vector3 = new Vector3()
	private readonly eye: Vector3 = new Vector3()
	private readonly objectUp: Vector3 = new Vector3()
	private readonly moveStart: Vector2 = new Vector2()
	private readonly moveEnd: Vector2 = new Vector2()
	private readonly moveDelta: Vector2 = new Vector2()
	private readonly move: Vector3 = new Vector3()
	private readonly euler: Euler = new Euler(0, 0, 0, 'YXZ')
	private readonly eulerOffset: Euler = new Euler(0, 0, 0, 'YXZ')
	private readonly eulerDelay: Euler = new Euler(0, 0, 0, 'YXZ')
	private readonly activePointers: PointerEvent[] = []
	private readonly animator: Animator = new Animator()

	private width = 0
	private height = 0
	private pointerDistanceStart = 0
	private pointerDistance = 0
	private zoom = 1
	private zoomBounds = [0.5, 1.5]
	private cameraBaseDistance = 1

	private longPressTimer = null

	private isInitial = true
	private isPointerActive = false
	private isAnimating = false
	private isMultiTouch = false
	private needsUpdate = false
	private hasMomentum = false

	public isLocked = false
	public isPointerLocked = false
	public isLongPressed = false

	constructor(
		private readonly domElement: HTMLElement,
		private readonly chalet: Chalet
	) {
		this.onPointerStart = this.onPointerStart.bind(this)
		this.onPointerMove = this.onPointerMove.bind(this)
		this.onPointerEnd = this.onPointerEnd.bind(this)
		this.onWheel = this.onWheel.bind(this)
		this.onKeyDown = this.onKeyDown.bind(this)
		this.onLongPress = this.onLongPress.bind(this)

		this.domElement.addEventListener('pointerdown', this.onPointerStart)
		this.domElement.addEventListener('pointermove', this.onPointerMove)
		this.domElement.addEventListener('pointerup', this.onPointerEnd)
		this.domElement.addEventListener('pointercancel', this.onPointerEnd)
		this.domElement.addEventListener('pointerleave', this.onPointerEnd)
		this.domElement.addEventListener('wheel', this.onWheel)
		document.body.addEventListener('keydown', this.onKeyDown)
	}

	dispose(): void {
		window.clearTimeout(this.longPressTimer)
		this.domElement.removeEventListener('pointerdown', this.onPointerStart)
		this.domElement.removeEventListener('pointermove', this.onPointerMove)
		this.domElement.removeEventListener('pointerup', this.onPointerEnd)
		this.domElement.removeEventListener('pointercancel', this.onPointerEnd)
		this.domElement.removeEventListener('pointerleave', this.onPointerEnd)
		this.domElement.removeEventListener('wheel', this.onWheel)
		document.body.removeEventListener('keydown', this.onKeyDown)
	}

	resize(width: number, height: number): void {
		this.width = width
		this.height = height
	}

	getRelativeCoordinates(x: number, y: number, vector: Vector2): void {
		vector.x = MathUtils.clamp((x / this.width) * 2 - 1, -1, 1)
		vector.y = MathUtils.clamp(-(y / this.height) * 2 + 1, -1, 1)
	}

	onPointerStart(event: PointerEvent): void {
		if (this.isLocked) return
		if (this.isPointerLocked) return

		this.isLongPressed = false
		this.isPointerActive = true

		window.clearTimeout(this.longPressTimer)
		this.longPressTimer = window.setTimeout(this.onLongPress, LONG_PRESS_DURATION)

		this.activePointers.push(event)

		this.getRelativeCoordinates(event.x, event.y, this.pointer)

		this.moveStart.copy(this.pointer)
		this.moveEnd.copy(this.moveStart)

		if (this.activePointers.length === 2) {
			this.pointerDistance = getDistance2d(this.activePointers[0], this.activePointers[1])
			this.pointerDistanceStart = this.pointerDistance
		}
	}

	onPointerMove(event: PointerEvent): void {
		if (this.isLocked) return
		if (this.isPointerLocked) return

		window.clearTimeout(this.longPressTimer)

		for (let i = 0; i < this.activePointers.length; i++) {
			if (event.pointerId === this.activePointers[i].pointerId) {
				this.activePointers[i] = event
				break
			}
		}

		this.isMultiTouch = this.activePointers.length > 1

		this.getRelativeCoordinates(event.x, event.y, this.pointer)

		if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
			this.eulerOffset.y = this.pointer.x * ROTATE_SPEED
			this.eulerOffset.x = this.pointer.y * -1 * ROTATE_SPEED

			this.eulerOffset.y = MathUtils.clamp(this.eulerOffset.y, -0.25, 0.25)
			this.eulerOffset.x = MathUtils.clamp(this.eulerOffset.x, -0.25, 0.25)
		}

		if (!this.isAnimating && !this.isMultiTouch && this.activePointers.length === 1) {
			this.moveEnd.copy(this.pointer)

			if (!this.isDragging) {
				this.isDragging = true
				this.chalet.onCursorChange('move')
			}
		}

		if (!this.isAnimating && this.activePointers.length === 2) {
			this.pointerDistance = getDistance2d(this.activePointers[0], this.activePointers[1])
			const factor = this.pointerDistanceStart / this.pointerDistance
			this.pointerDistanceStart = this.pointerDistance
			this.zoom *= factor
			this.zoom = MathUtils.clamp(this.zoom, this.zoomBounds[0], this.zoomBounds[1])
		}

		this.needsUpdate = true
	}

	onPointerEnd(event: PointerEvent): void {
		if (this.isLocked) return
		if (this.isPointerLocked) return

		window.clearTimeout(this.longPressTimer)

		const index = this.activePointers.findIndex(({ pointerId }) => pointerId === event.pointerId)
		if (!this.activePointers[index]) return //prevent multiple triggers if touch -> pointerup followed by pointerleave

		this.activePointers.splice(index, 1)

		if (this.activePointers.length === 0) {
			if (this.isMultiTouch) {
				this.isMultiTouch = false //stay in multiTouch state until all touches are released
			}

			this.isPointerActive = false
			this.isDragging = false
			this.chalet.onControlsEnd()
		}
	}

	onWheel(event: WheelEvent): void {
		if (this.isLocked) return
		if (this.isPointerLocked) return
		if (this.isAnimating) return

		const delta = event.deltaMode === WheelEvent.DOM_DELTA_LINE ? event.deltaY * LINE_HEIGHT : event.deltaY
		const factor = 1 + delta * WHEEL_SPEED
		this.zoom *= factor
		this.zoom = MathUtils.clamp(this.zoom, this.zoomBounds[0], this.zoomBounds[1])

		this.chalet.onControlsEnd()
		this.needsUpdate = true
	}

	onKeyDown(event: KeyboardEvent): void {
		if (this.isLocked) return
		if (this.isPointerLocked) return

		if (event.key === 'ArrowDown') {
			this.zoom += ARROW_ZOOM_SPEED
		} else if (event.key === 'ArrowUp') {
			this.zoom -= ARROW_ZOOM_SPEED
		} else return

		this.zoom = MathUtils.clamp(this.zoom, this.zoomBounds[0], this.zoomBounds[1])

		this.chalet.onControlsEnd()
		this.needsUpdate = true
	}

	onLongPress(): void {
		this.chalet.onLongPress()
		this.isLongPressed = true
	}

	lock(): void {
		this.isLocked = true
	}

	unlock(): void {
		this.isLocked = false
	}

	lockPointer(): void {
		this.isPointerLocked = true
	}

	unlockPointer(): void {
		this.isPointerLocked = false
	}

	async navigate(
		cameraPosition: Vector3,
		cameraTarget: Vector3,
		zoomBounds: [number, number],
		immediate = false
	): Promise<void> {
		if (this.isLocked) return

		this.animator.stopAnimations()

		this.zoomBounds = zoomBounds
		this.eye.subVectors(cameraPosition, cameraTarget)

		const targetDistance = this.target.distanceTo(cameraTarget)
		const positionDistance = this.chalet.camera.position.distanceTo(cameraPosition)
		const movementDistance = targetDistance + positionDistance
		const cameraDistance = this.eye.length()
		const duration = immediate || this.isInitial ? 0 : MathUtils.clamp(movementDistance * 250, 500, 2000)

		this.isAnimating = true
		this.isInitial = false

		this.animator.animate(this.target, cameraTarget.clone(), {
			duration,
			easing: Easing.Cubic.InOut,
			onUpdate: () => this.chalet.composer.requestRender()
		})
		this.animator.animate(this.chalet.camera.position, cameraPosition.clone(), {
			duration,
			easing: Easing.Cubic.InOut,
			onUpdate: () => this.chalet.composer.requestRender()
		})
		this.animator.animate(
			{ distance: this.cameraBaseDistance },
			{ distance: cameraDistance },
			{
				duration,
				easing: Easing.Cubic.InOut,
				onUpdate: ({ distance }) => {
					this.cameraBaseDistance = distance
					this.chalet.composer.requestRender()
				}
			}
		)
		await this.animator.animate(
			{ zoom: this.zoom },
			{ zoom: 1 },
			{
				duration,
				easing: Easing.Cubic.InOut,
				onUpdate: ({ zoom }) => {
					this.zoom = zoom
					this.chalet.composer.requestRender()
				}
			}
		)

		this.isAnimating = false
	}

	getCameraCenter(): Vector3 {
		//TODO reuse
		const cameraPosition = new Vector3()
		this.chalet.camera.getWorldPosition(cameraPosition)

		return this.target.clone().sub(this.target.clone().sub(cameraPosition).multiplyScalar(0.5))
	}

	update(): void {
		if (this.isLocked) return

		if (this.needsUpdate || this.isAnimating || this.hasMomentum) {
			this.needsUpdate = false

			//set zoom
			this.eye.subVectors(this.chalet.camera.position, this.target)
			this.eye.setLength(this.cameraBaseDistance * this.zoom)
			this.chalet.camera.position.copy(this.target).add(this.eye)

			//set rotation
			this.chalet.camera.lookAt(this.target)
			this.euler.setFromQuaternion(this.chalet.camera.quaternion)

			const eulerX = (this.eulerOffset.x - this.eulerDelay.x) * ROTATE_DAMPING
			const eulerY = (this.eulerOffset.y - this.eulerDelay.y) * ROTATE_DAMPING

			this.eulerDelay.x += eulerX
			this.eulerDelay.y += eulerY

			this.euler.x -= this.eulerDelay.x
			this.euler.y -= this.eulerDelay.y
			this.chalet.camera.quaternion.setFromEuler(this.euler)

			// pan main object
			this.moveDelta.multiplyScalar(this.eye.length() * PAN_SPEED)

			this.move.copy(this.eye).cross(this.chalet.camera.up).setLength(this.moveDelta.x)
			this.move.add(this.objectUp.copy(this.chalet.camera.up).setLength(this.moveDelta.y * -1))

			this.moveStart.add(this.moveDelta.subVectors(this.moveEnd, this.moveStart).multiplyScalar(PAN_DAMPING))

			this.target.add(this.move)
			this.chalet.camera.position.add(this.move)

			if (!this.isAnimating) {
				this.target.y = Math.max(this.target.y, 0.1)
				this.chalet.camera.position.y = Math.max(this.chalet.camera.position.y, 0.1)
			}

			this.hasMomentum =
				Math.abs(eulerX) > ROTATE_THRESHOLD ||
				Math.abs(eulerY) > ROTATE_THRESHOLD ||
				this.move.length() > PAN_THRESHOLD

			if (!this.isDragging) {
				this.chalet.raycaster.update(this.pointer, this.chalet.camera)
			}

			this.chalet.composer.requestRender()
		}
	}
}
