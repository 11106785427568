import {
	Color,
	DynamicDrawUsage,
	Group,
	InstancedBufferAttribute,
	InstancedMesh,
	MathUtils,
	Object3D,
	PlaneGeometry,
	RawShaderMaterial,
	TextureLoader,
	Vector3
} from 'three'

import tex from './particle.png'
import fragmentShader from './smoke.frag'
import vertexShader from './smoke.vert'
import { SmokeParticle } from './SmokeParticle'

export class Smoke {
	private readonly object: Group = new Group()
	private readonly particles: SmokeParticle[] = []
	private readonly mesh: InstancedMesh<PlaneGeometry, RawShaderMaterial>
	private readonly delay = 125
	private readonly count = 50
	private readonly scale = 1
	private lastTime: number = performance.now()

	constructor(parent: Object3D) {
		this.particles = []

		for (let i = 0; i < this.count; i++) {
			this.particles.push(new SmokeParticle(i))
		}

		const geometry = new PlaneGeometry()
		const elapsed = new Float32Array(this.count).fill(0)
		const opacity = new Float32Array(this.count)
		for (let i = 0; i < opacity.length; i++) {
			opacity[i] = MathUtils.randFloat(0.05, 0.1)
		}

		geometry.setAttribute('elapsed', new InstancedBufferAttribute(elapsed, 1, true, 1))
		geometry.setAttribute('opacity', new InstancedBufferAttribute(opacity, 1, true, 1))

		const material = new RawShaderMaterial({
			vertexShader,
			fragmentShader,
			transparent: true,
			depthWrite: false,
			uniforms: {
				map: { value: new TextureLoader().load(tex) },
				time: { value: performance.now() },
				color: { value: new Color(0xffffff) }
			}
		})

		this.mesh = new InstancedMesh(geometry, material, this.count)
		this.mesh.instanceMatrix.setUsage(DynamicDrawUsage)

		this.object.scale.set(this.scale, this.scale, this.scale)
		this.object.add(this.mesh)
		parent.add(this.object)
	}

	setPosition(position: Vector3): void {
		this.object.position.copy(position)
	}

	start(): void {
		const particle = this.particles.find((particle) => !particle.isRunning)
		if (!particle) console.log('miss')
		particle?.start()
	}

	update(time: number, lookAt: Vector3): void {
		if (time - this.lastTime > this.delay) {
			this.lastTime = time
			this.start()
		}

		for (let i = 0; i < this.count; i++) {
			this.mesh.setMatrixAt(i, this.particles[i].matrix)
			// @ts-ignore
			this.mesh.geometry.attributes.elapsed.array[i] = this.particles[i].elapsed
		}

		this.object.lookAt(lookAt)
		this.mesh.instanceMatrix.needsUpdate = true
		this.mesh.geometry.attributes.elapsed.needsUpdate = true
		this.mesh.material.uniforms.time.value = time
	}
}
