import TWEEN from '@tweenjs/tween.js'
import { Clock, PerspectiveCamera, Scene, Vector3 } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import Controls from './controls/Controls'
import Indoor, { IndoorState } from './indoor/Indoor'
import ProductView from './productView/ProductView'
import Outdoor from './outdoor/Outdoor'
import Composer from './composer/Composer'
import RayCaster from './rayCaster/RayCaster'

export const LABELS = {
	labelOverview: 'CIAO CIAO',
	labelIntro: 'ANS CHEMINÉE',
	labelProducts: 'ZUM REGAL',
	labelEvents: 'EVENTS/VIDEOS',
	labelNews: 'LINKEDIN',
	labelLibrary: 'BIBLIOTHEK'
}

export const LAYER: { [key in ChaletState]: number } = {
	index: 10,
	overview: 11,
	intro: 12,
	products: 13,
	events: 14,
	video: 15,
	library: 16,
	productview: 17,
	outro: 18,
	presentation: 19,
	anniversary: 20
}

export const PRODUCT_LAYER = {
	hidden: 0,
	visible: 30,
	active: 31
}

export const ZOOM: { [key in ChaletState]: [number, number] } = {
	index: [0.75, 1.25],
	overview: [0.5, 1],
	intro: [0.5, 3],
	products: [0.5, 3],
	events: [0.85, 1.1],
	video: [1, 1],
	library: [0.5, 1.5],
	productview: [0.5, 3],
	outro: [0.5, 1.5],
	presentation: [0.5, 3],
	anniversary: [0.5, 1.5]
}

export type IntroElementType = 'avatar' | 'ui' | 'lock' | 'questionmark' | 'headphones' | 'clock'
export type ChaletState = 'index' | IndoorState | 'outro'
export type ChaletCategory = 'rx' | 'otc' | 'rx-ad'
export type ChaletSeason = 'spring' | 'summer' | 'fall' | 'winter'

export default class Chalet {
	private readonly clock: Clock = new Clock()

	private readonly indoor: Indoor
	private readonly outdoor: Outdoor
	private view: Indoor | Outdoor = null

	private readonly productView: ProductView
	private readonly resizeObserver: ResizeObserver

	private onNavigate: (href) => void = null

	private isNavigating = false
	private width = 0
	private height = 0

	public state: ChaletState = null
	public readonly scene: Scene = new Scene()
	public readonly composer: Composer
	public readonly controls: Controls
	public readonly raycaster: RayCaster
	public readonly camera: PerspectiveCamera = new PerspectiveCamera(36, 1, 0.1, 50)
	public readonly cameraWorldPosition: Vector3 = new Vector3()
	public readonly cameraWorldDirection: Vector3 = new Vector3()

	constructor(
		public readonly domElement: HTMLElement,
		public readonly season: ChaletSeason,
		public readonly onCursorChange: (type: 'auto' | 'pointer' | 'move') => void,
		private readonly onLoadingChange: (loading) => void,
		private readonly setShowOutroText: (show: boolean) => void
	) {
		this.onClick = this.onClick.bind(this)
		this.onLongPress = this.onLongPress.bind(this)
		this.onControlsEnd = this.onControlsEnd.bind(this)
		this.update = this.update.bind(this)
		this.resize = this.resize.bind(this)

		this.controls = new Controls(this.domElement, this)
		this.raycaster = new RayCaster()

		this.composer = new Composer(this)

		const loader = new GLTFLoader()

		this.outdoor = new Outdoor({
			parent: this.scene,
			chalet: this,
			loader
		})

		this.indoor = new Indoor({
			parent: this.scene,
			camera: this.camera,
			chalet: this,
			loader
		})

		this.productView = new ProductView({ domElement, chalet: this })

		// @ts-ignore
		this.raycaster.addEventListener('over', ({ id }) => {
			id ? onCursorChange('pointer') : onCursorChange('auto')
		})

		this.resize()
		this.domElement.addEventListener('click', this.onClick)
		this.resizeObserver = new ResizeObserver(this.resize)
		this.resizeObserver.observe(this.domElement)

		requestAnimationFrame(this.update)
	}

	async navigate(state: ChaletState): Promise<void> {
		this.isNavigating = false
		this.raycaster.disable()
		this.controls.unlockPointer()

		if (this.state !== state) {
			this.state = state

			await this.composer.setMode('default')

			const oldView = this.view
			if (this.state === 'index') {
				this.view = this.outdoor
			} else {
				this.view = this.indoor
			}

			this.onLoadingChange(true)
			await this.view.load()
			this.onLoadingChange(false)

			if (oldView !== this.view) {
				await this.composer.showFade()

				oldView?.hide()
				this.view.show()

				const { cameraPosition, cameraTarget } = this.view.navigate(state)
				await this.controls.navigate(cameraPosition, cameraTarget, ZOOM[state], true)

				await this.composer.hideFade()
			} else {
				const { cameraPosition, cameraTarget } = this.view.navigate(state)
				await this.controls.navigate(cameraPosition, cameraTarget, ZOOM[state])
			}

			this.raycaster.setLayer(LAYER[state])

			if (this.state === 'library' || this.state === 'presentation') {
				this.controls.lockPointer()
				await this.composer.setMode('blur')
				this.composer.fadeInBlur()
			}

			// preload scenes
			this.preload()
		}

		this.raycaster.enable()
	}

	preload(): void {
		const load = () => {
			if (this.state === 'index') {
				this.indoor.load()
			} else {
				this.outdoor.load()
			}
		}

		if ('requestIdleCallback' in window) {
			window.requestIdleCallback(load)
		} else {
			load()
		}
	}

	showIntroElement(element: string): void {
		this.indoor.showIntroElement(element)
	}

	setOnNavigate(cb: (href) => void): void {
		this.onNavigate = cb

		// Reset the isNavigating, in case the server sends
		// a redirect, f.e. to <current url>?login
		this.isNavigating = false
	}

	setLocale(): void {
		this.indoor.setLocale()
		this.outdoor.setLocale()
	}

	onControlsEnd(): void {
		if (this.state === 'events' || this.state === 'video') return
		this.onCursorChange('auto')
		const newState = this.view?.snap(this.controls.getCameraCenter()) ?? null
		if (newState !== null) {
			this.triggerNavigation(newState)
		}
	}

	onClick(event: MouseEvent): void {
		if (this.controls.isLongPressed) return
		event.preventDefault()
		if (this.state === 'productview') return // Don't need to ray cast in product detail view
		const userData = this.raycaster.update(this.controls.pointer, this.camera)
		if (userData?.disabled) return
		this.triggerNavigation(userData ? userData.href : null)
	}

	onLongPress(): void {
		// only test in products view
		// if (this.category === 'rx-ad' && this.view instanceof Indoor) {
		// 	const userData = this.raycaster.update(this.controls.pointer, this.camera)
		// 	if (userData?.id) {
		// 		this.view.disableProduct(userData.id)
		// 		this.composer.requestRender()
		// 	}
		// }
	}

	triggerNavigation(href: ChaletState | string | null): void {
		if (href === null) return
		if (this.state === href) return
		if (this.isNavigating) return

		this.isNavigating = !this.isExternalUrl(href) //only set isNavigating if is not external url

		if (this.onNavigate) {
			this.onNavigate(href)
		}
	}

	showOutroText(show: boolean): void {
		this.setShowOutroText(show)
	}

	isExternalUrl(href: string): boolean {
		try {
			new URL(href)
			return true
		} catch (error) {
			return false
		}
	}

	resize(): void {
		const { width, height } = this.domElement.getBoundingClientRect()

		if (this.width === width) {
			//prevent resize events on scroll in old ios version
			return
		}

		this.width = width
		this.height = height

		const aspect = width / height
		this.camera.aspect = aspect
		this.camera.zoom = Math.min(aspect + 0.1, 1)
		this.camera.updateProjectionMatrix()

		this.composer.resize(width, height, window.devicePixelRatio)
		this.productView.resize(width, height, window.devicePixelRatio, this.camera)
		this.controls.resize(width, height)
	}

	update(time: number): void {
		requestAnimationFrame(this.update)

		TWEEN.update(time)

		const delta = this.clock.getDelta()
		this.camera.getWorldPosition(this.cameraWorldPosition)
		this.camera.getWorldDirection(this.cameraWorldDirection)

		this.controls.update()
		this.productView.update(delta)
		this.view?.update(delta, time, this.cameraWorldPosition)

		this.composer.update()
	}
}
