import {
	AdditiveBlending,
	Color,
	DynamicDrawUsage,
	Frustum,
	Group,
	InstancedBufferAttribute,
	InstancedMesh,
	Matrix4,
	Mesh,
	Object3D,
	PlaneGeometry,
	RawShaderMaterial,
	TextureLoader,
	Vector3
} from 'three'

import Chalet from '@/ui/components/scene/Chalet'

import fragmentShader from './fire.frag'
import vertexShader from './fire.vert'
import { FireParticle } from './FireParticle'
import Sparks from './Sparks'
import tex from './tex.png'

export class Fire {
	private readonly object: Group = new Group()
	private readonly particles: FireParticle[] = []
	private readonly mesh: InstancedMesh<PlaneGeometry, RawShaderMaterial>
	private readonly bloomMesh: InstancedMesh<PlaneGeometry, RawShaderMaterial>
	private readonly frustum: Frustum = new Frustum()
	private readonly box: Mesh<PlaneGeometry>
	private readonly sparks: Sparks
	private readonly delay = 300
	private readonly count = 14
	private readonly scale = 0.45
	private readonly matrix: Matrix4 = new Matrix4()

	private lastTime = performance.now()

	constructor(
		parent: Object3D,
		private readonly chalet: Chalet
	) {
		this.particles = []

		this.sparks = new Sparks(this.object)

		for (let i = 0; i < this.count; i++) {
			this.particles.push(new FireParticle(i))
		}

		const dissolves = new Float32Array(this.count).fill(1)
		const geometry = new PlaneGeometry()
		geometry.setAttribute('dissolve', new InstancedBufferAttribute(dissolves, 1, true, 1))

		const texture = new TextureLoader().load(tex)

		const material = new RawShaderMaterial({
			vertexShader,
			fragmentShader,
			transparent: true,
			depthWrite: false,
			blending: AdditiveBlending,
			uniforms: {
				map: { value: texture },
				color: { value: new Color(0xfc8434) },
				alpha: { value: 1 },
				fade: { value: 0.1 }
			}
		})

		const bloomMaterial = new RawShaderMaterial({
			vertexShader,
			fragmentShader,
			transparent: true,
			depthWrite: false,
			blending: AdditiveBlending,
			uniforms: {
				map: { value: texture },
				color: { value: new Color(0xffa04d) },
				alpha: { value: 0.9 },
				fade: { value: 0.5 }
			}
		})

		this.mesh = new InstancedMesh(geometry, material, this.count)
		this.mesh.instanceMatrix.setUsage(DynamicDrawUsage)

		this.bloomMesh = new InstancedMesh(geometry, bloomMaterial, this.count)
		this.bloomMesh.instanceMatrix.setUsage(DynamicDrawUsage)

		this.object.scale.set(this.scale, this.scale, this.scale)
		this.object.add(this.mesh)
		this.object.add(this.bloomMesh)
		parent.add(this.object)

		this.box = new Mesh(new PlaneGeometry(1.8, 2.4))
		this.box.position.y += 0.6
		this.box.visible = false
		this.object.add(this.box)
	}

	setPosition(position: Vector3): void {
		this.object.position.copy(position)
	}

	start(): void {
		const particle = this.particles.find((particle) => !particle.isRunning)
		particle?.start()
	}

	update(time: number, lookAt: Vector3): void {
		this.matrix.identity()
		this.frustum.setFromProjectionMatrix(
			this.matrix.multiplyMatrices(this.chalet.camera.projectionMatrix, this.chalet.camera.matrixWorldInverse)
		)
		if (!this.frustum.intersectsObject(this.box)) return

		if (time - this.lastTime > this.delay) {
			this.lastTime = time
			this.start()
		}

		this.sparks.update(time, lookAt)

		for (let i = 0; i < this.count; i++) {
			this.mesh.setMatrixAt(i, this.particles[i].matrix)
			this.bloomMesh.setMatrixAt(i, this.particles[i].matrix)
			// @ts-ignore
			this.mesh.geometry.attributes.dissolve.array[i] = this.particles[i].dissolve
			// @ts-ignore
			this.bloomMesh.geometry.attributes.dissolve.array[i] = this.particles[i].dissolve
		}

		this.object.lookAt(lookAt)
		this.mesh.instanceMatrix.needsUpdate = true
		this.bloomMesh.instanceMatrix.needsUpdate = true
		this.mesh.geometry.attributes.dissolve.needsUpdate = true
		this.bloomMesh.geometry.attributes.dissolve.needsUpdate = true

		this.chalet.composer.requestRender()
	}
}
